import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import LoginForm from './Components/login/LoginForm';

import GetSheds from './Components/GetSheds';
import PostShed from './Components/PostShed';

import CompanyForm from './Components/company/CompanyForm';
import CompaniesList from './Components/company/CompaniesList';

import UserForm from './Components/user/UserForm';
import UserList from './Components/user/UserList';

import ProductList from './Components/product/ProductList';
import AddProduct from './Components/product/AddProduct';

import IncomeList from './Components/income/IncomeList';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainPage from './Components/MainPage';
import Protected from './Components/Protected';
import Preload from './Preload';
import Dashboard from './Components/Dashboard/Dashboard';
import ViewProfile from './Components/user/ViewProfile';
import PoultryFarm from './Components/Dashboard/PoultryFarm';
import Register from './Components/login/Register';
import { useEffect } from 'react';
// Animation package
import Aos from "aos";
import "aos/dist/aos.css";
import { ToastContainer } from 'react-toastify';
import CardFromTheme from './Components/income/CardFromTheme';
import IncomeForm from './Components/Forms/IncomeForm';
import InventoryFrom from './Components/Forms/InventoryFrom';
import ListInventory from './Components/ListView/ListInventory';
import EmployeeForm from './Components/Forms/EmployeeForm';
import EmployeeList from './Components/ListView/EmployeeList';
import ExpenseList from './Components/expenses/Expenselist';
import ExpenseForm from './Components/Forms/ExpenceForm';
import FlocksForm from './Components/Forms/FlocksForm';
import FlocksList from './Components/ListView/FlocksList';

function App() {
  useEffect(() => {
    Aos.init({
      duration: 1800,
      offset: 100,
      disable: "mobile",
    });
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Preload />
        <Routes>
          <Route path='/login' element={<LoginForm />} />
          <Route path='/Register' element={<Register />} />
          
          <Route path='/' element={<Protected Component={<Dashboard />} />} />
          <Route path='/ViewProfile' element={<Protected Component={<ViewProfile />} />} />
          <Route path='/MainPage' element={<Protected Component={<MainPage />} />} />

          <Route path='/Show-Sheds' element={<Protected Component={<PoultryFarm />} />} />
          <Route path='/Add-Shed' element={<Protected Component={<PostShed />} />} />

          <Route path='/Flocks-list' element={<Protected Component={<FlocksList />} />} />
          <Route path='/add-Flocks' element={<Protected Component={<CardFromTheme><FlocksForm /></CardFromTheme>} />} />


          {/**
          <Route path='/company-list' element={<Protected Component={<CompaniesList />} />} />
          <Route path='/add-company' element={<Protected Component={<CompanyForm />} />} />
          */}


          <Route path='/user-list' element={<Protected Component={<UserList />} />} />
          <Route path='/add-user' element={<Protected Component={<UserForm />} />} />
          
          <Route path='/product-list' element={<Protected Component={<ProductList />} />} />
          <Route path='/add-product' element={<Protected Component={<AddProduct />} />} />

          <Route path='/Employee-list' element={<Protected Component={<EmployeeList />} />} />
          <Route path='/add-Employee' element={<Protected Component={<CardFromTheme><EmployeeForm /></CardFromTheme>} />} />

          <Route path='/inventory-list' element={<Protected Component={<ListInventory />} />} />
          <Route path='/add-inventory' element={<Protected Component={<CardFromTheme><InventoryFrom /></CardFromTheme>} />} />

          <Route path='/income-list' element={<Protected Component={<IncomeList />} />} />
          <Route path='/add-income' element={<Protected Component={<CardFromTheme><IncomeForm /></CardFromTheme>} />} />

          <Route path='/expense-list' element={<Protected Component={<ExpenseList />} />} />
          <Route path='/add-expense' element={<Protected Component={<CardFromTheme><ExpenseForm /></CardFromTheme>} />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
