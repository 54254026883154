import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Inventory, PoultryFarmState, ShedsState, UpdateHandle, defaultApiUrl, incomes } from '../../Atom';
import { useRecoilState, useRecoilValue } from 'recoil';
import axios from 'axios';
import { toast } from 'react-toastify';

const InventoryFrom = (props) => {

  let defaultApi = useRecoilValue(defaultApiUrl);
  let PoultryFarms = useRecoilValue(PoultryFarmState);
  let shedsState = useRecoilValue(ShedsState);
  let updateHandle = useRecoilValue(UpdateHandle);

  let [inventory, setInventory] = useRecoilState(Inventory);

  let [updateFlag, setUpdateFlag] = useState(true);

  var navigate = useNavigate();

  const location = useLocation();

  // Access the route path (without query parameters or hash):
  const currentRoute = location.pathname;

  // Access the query parameters (if any):
  const queryString = location.search;

  // Access the hash fragment (if any):
  const hashFragment = location.hash;

  useEffect(() => {
    console.log(hashFragment, "Update object", updateHandle);
    if (hashFragment === "#update") {
      setUpdateFlag(false);
    }
  }, [hashFragment])// eslint-disable-line react-hooks/exhaustive-deps


  let initialValues = {
    poultryFarm_FK: JSON.parse(sessionStorage.getItem('PreSelectFarm')) || '',
    quantity: "",
    // price: "",
    shed_FK: "",
    user_FK: JSON.parse(sessionStorage.getItem('UserData'))._id,
    name: "",
    inventoryType: "",  //"Shed inventory"/'purchased inventory'
  }

  let validationSchema = Yup.object({

    quantity: Yup.string().required('Required').test(
      'Is positive?',
      'quantity must be positive',
      (value) => value > -1
    ),

    poultryFarm_FK: Yup.string().required("Please! select Poultry Farm"),
    // shed_FK: Yup.string().required("Please! select Shed of Poultry Farm"),
    shed_FK: Yup.string().when('poultryFarm_FK', {
      is: (poultryFarm_FK) => !poultryFarm_FK || poultryFarm_FK.length === 0,
      then: Yup.string().required("Select Poultry Farm First"),
      otherwise: Yup.string().required("Shed is required")
    }),

    inventoryType: Yup.string().required("Please! select inventory Type"),

    // incomeDate: Yup.date().required()

  })

  let onSubmit = async (values, action) => {
    let myObj = {
      poultryFarm_FK: values.poultryFarm_FK,
      quantity: values.quantity,
      shed_FK: values.shed_FK,
      user_FK: values.user_FK,
      name: values.name,
      inventoryType: values.inventoryType,
      user_FK: JSON.parse(sessionStorage.getItem("UserData"))._id
    }


    if (updateFlag) {
      axios
        .post(defaultApi + "/api/inventory/Add", myObj)
        .then((res) => {
          console.log(res.data.addInventory);

          setInventory([res.data.addInventory, ...inventory]);

          toast.success("Inventory added successfully", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          setTimeout(function () {
            // action.resetForm();
            navigate("/inventory-list")
          }, 2000);

          if (props.open) {
            props.setOpen(false);
          }
        }
        ).catch((err) => {
          console.log(err);
        });
    } else {
      myObj._id = updateHandle._id;

      axios
        .patch(defaultApi + "/api/inventory/update", myObj)
        .then((res) => {
          console.log(res.data);


          const updatedInventory = res.data;
          setInventory(prevInventory => prevInventory.map(inventory =>
            inventory._id === updatedInventory._id ? updatedInventory : inventory
          ));

          // setInventory([res.data.addInventory, ...inventory]);

          toast.success("Inventory Updated successfully", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          setTimeout(function () {
            // action.resetForm();
            navigate("/inventory-list")
          }, 2000);

          if (props.open) {
            props.setOpen(false);
          }
        }
        ).catch((err) => {
          console.log(err);
        });


    }

  }

  return (
    <Formik
      initialValues={hashFragment === "#update" ? updateHandle : initialValues}
      // validationSchema={validationSchema}
      onSubmit={onSubmit}
      innerRef={props.formRef}
      enableReinitialize
    >
      {({ values }) => {

        return (
          <Form>

            <div className='row mt-4'>
              <div className='col-md-12 text-center'>
                <h3><b>EGGS INVENTORY</b></h3>
              </div>
            </div>

            {/* added by */}
            <div className='row mt-3 justify-content-center'>

              <div className="col-md-10 mt-2">
                <label for="poultryFarm_FK">Poultry Farm</label>
                <Field
                  as="select"
                  className="form-select"
                  id="poultryFarm_FK"
                  name="poultryFarm_FK"
                  disabled={updateFlag === false ? true : false}
                >
                  <option value="" label="Select" />

                  {PoultryFarms.map((option, index) => (
                    <option key={index} value={option._id}>
                        {option.name}
                    </option>
                ))}
                </Field>
                <ErrorMessage name="poultryFarm_FK" className='text-danger' component={"div"} />
              </div>

              <div className="col-md-10 mt-2">
                <label for="shed_FK">Sheds Farm</label>
                <Field
                  as="select"
                  className="form-select"
                  id="shed_FK"
                  name="shed_FK"
                  disabled={updateFlag === false ? true : false}
                >
                  <option value="" label="Select" />
                  {shedsState.map((option, index) => {
                    if (values.poultryFarm_FK === option.poultryFarm_FK) {
                      return (<option key={index} value={option._id}>
                        {option.name}
                      </option>)
                    }
                  })}
                </Field>
                <ErrorMessage name="shed_FK" className='text-danger' component={"div"} />
              </div>


              <div className="col-md-10 mt-2">
                <label for="name">Name</label>
                <Field as="select" autoComplete='off' id="name" className="form-select py-1" name="name" >
                  <option value="">Select</option>
                  <option value="Eggs">Eggs</option>
                </Field>
                <ErrorMessage name="name" className='text-danger' component={"div"} />
              </div>

              <div className="col-md-10 mt-2">
                <label for="quantity">Quantity</label>
                <Field autoComplete='off' id="quantity" placeholder="Quantity" className="form-control py-1" type="number" name="quantity" />
                <ErrorMessage name="quantity" className='text-danger' component={"div"} />
              </div>

              <div className="col-md-10 mt-2">
                <label for="inventoryType">Inventory Type</label>
                <Field
                  as="select"
                  className="form-select"
                  id="inventoryType"
                  name="inventoryType"
                // disabled={updateFlag === false ? true : false}
                >
                  <option value="" label="Select" />
                  <option value="shedIn" label="Eggs inventory" />
                </Field>
                <ErrorMessage name="inventoryType" className='text-danger' component={"div"} />
              </div>

            </div>
            <div className={`row my-4 `}>
              <div className='col-md-12 text-center'>
                <button type='button' className='ButtonPrimaryReverse me-3' id="SubmitButton1" onClick={() => { navigate("/inventory-list") }}>Cancel</button>
                <button type='submit' className='ButtonPrimary' id="SubmitButton">Submit</button>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default InventoryFrom
