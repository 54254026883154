import React, { useState } from 'react'
import { Button, Form, Input, Upload, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLocationDot, faPhone, faPlus, faTrashCan, faUser } from '@fortawesome/free-solid-svg-icons'



const NewUserFrom = ({ SampleData, setSampleData }) => {

    const [uploadedImage, setUploadedImage] = useState(null); // Store uploaded image data

    const onUploadChange = (info) => {
        console.log(info.target.files)
        const FileList = info.target.files; // Destructure fileList from info

        const uploadedFile = FileList && FileList.length > 0 ? FileList[0] : null; // Get the first file from fileList

        console.log("uploadedFile", uploadedFile);

        if (uploadedFile) {
            // Handle successful upload and store image data
            setUploadedImage(uploadedFile); // Update state with uploaded image object
            message.success('Image uploaded successfully!');
        }
    };

    const validateMessages = {// eslint-disable-next-line
        required: '${label} is required!',  // eslint-disable-next-line
        types: {// eslint-disable-next-line
            email: '${label} is not a valid email!',// eslint-disable-next-line
            number: '${label} is not a valid number!',// eslint-disable-next-line
            date: '${label} is required',// eslint-disable-next-line
        },
        number: {// eslint-disable-next-line
            range: '${label} must be between ${min} and ${max}',// eslint-disable-next-line
        },
    };

    const onBeforeUpload = (file) => {
        // Prevent default behavior (uploading immediately)
        console.log(file)
        if (uploadedImage) {
            message.error('You can only upload one image.');
            return false;
        }
        return false;
    };
    const [form] = Form.useForm();

    const layout = {
        labelCol: { span: `auto` },
        wrapperCol: { span: 24 },
        layout: "vertical",
    };


    const onFinish = (values) => {
        console.log("after change :", values);

        let Data = JSON.parse(JSON.stringify(SampleData));
        Data.Name = values.Name;
        Data.email = values.email;
        Data.phoneNumber = values.phoneNumber;
        Data.address = values.address;
        Data.imageUrl = uploadedImage;

        setSampleData(Data);

        // const formData = new FormData();
        // formData.append("name", values.name);

    }
    return (
        <div>
            <Form
                {...layout}
                name="nest-messages"
                onFinish={onFinish}
                initialValues={SampleData}
                validateMessages={validateMessages}
                form={form}
            >
                <div className='row'>
                    <div className='col-md-6'>
                        <Form.Item name={['Name']} label="Name">
                            <Input prefix={<FontAwesomeIcon icon={faUser} className="site-form-item-icon" />} />
                        </Form.Item>
                    </div>
                    <div className='col-md-6'>
                        <Form.Item name={['email']} label="Email" rules={[{ type: 'email' }]}>
                            <Input prefix={<FontAwesomeIcon icon={faEnvelope} className="site-form-item-icon" />} />
                        </Form.Item>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <Form.Item name={['phoneNumber']} label="Phone Number">
                            <Input prefix={<FontAwesomeIcon icon={faPhone} className="site-form-item-icon" />} />
                        </Form.Item>
                    </div>
                    <div className='col-md-6'>
                        <Form.Item name={['address']} label="Address">
                            <Input prefix={<FontAwesomeIcon icon={faLocationDot} className="site-form-item-icon" />} />
                        </Form.Item>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-12'>
                        <Form.Item label="Upload" valuePropName="file" onChange={onUploadChange}>
                            {uploadedImage ? (
                                <div className='row ms-2'>
                                    <div className='col-md-2 position-relative border-dotted imageHover p-0'>
                                        <div className='position-absolute left-0 top-0 text-danger deleteImg  justify-content-center align-items-center' onClick={() => { setUploadedImage(null); }} >
                                            <FontAwesomeIcon icon={faTrashCan} />
                                        </div>
                                        <img src={URL.createObjectURL(uploadedImage)} alt="Uploaded" style={{ width: "7rem", height: "7rem" }} />
                                    </div>
                                </div>
                            ) : (
                                <Upload listType="picture-card" beforeUpload={onBeforeUpload} disabled={uploadedImage ? true : false}>
                                    <button type="button" style={{ border: 0, background: 'none' }}>
                                        <FontAwesomeIcon icon={faPlus} />
                                        <div style={{ marginTop: 8 }}>Upload</div>
                                    </button>
                                </Upload>
                            )}
                        </Form.Item>
                    </div>
                </div>



                <Form.Item wrapperCol={{ ...layout.wrapperCol }}>
                    <Button type="primary" htmlType="submit" className='w-25 float-end'>
                        Submit
                    </Button>
                </Form.Item>
            </Form>

        </div>
    )
}

export default NewUserFrom
