import React from 'react';
import './GetSheds.css';

import { useEffect, useState } from 'react';

import Shed4Picture from '../Pictures/Shed3Picture.jpg';

import { useNavigate } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faEgg, faLocationDot } from '@fortawesome/free-solid-svg-icons';



function DeleteShed(ShedID) {

  if (window.confirm("Want to delete this Shed?!?")) {
    const Shed = {
      id: ShedID,
    };

    fetch('http://localhost:7000/Shed/Delete-Shed', {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(Shed)
    }).then(resp => resp.json()).then(info => console.log());

    toast.success('Shed is deleted Successfully!', {
      position: "top-center",
      autoClose: 500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    setTimeout(
      function () {
        window.location.reload();
      }, 2000
    );

  }
  else {

  }

}

function GetSheds() {

  const [Sheds, setSheds] = useState([]);
  const [Expense, setExpense] = useState([]);
  const [Income, setIncome] = useState([]);

  const [ShedExpense, setShedExpense] = useState([]);
  const [ShedIncome, setShedIncome] = useState([]);
  const [ShedName, setShedName] = useState([]);

  var particularShedExpense = 0;
  var particularShedIncome = 0;

  let UserName = sessionStorage.getItem("UserName");
  let Navigate = useNavigate();

  function EditShed(Element) {
    document.getElementById("ShedRow").classList.add("d-none");
    document.getElementById("UpdateFormRow").classList.remove("d-none");

    document.getElementById("idInput").value = Element.id;
    document.getElementById("nameInput").value = Element.Name;
    document.getElementById("locationInput").value = Element.Location;
    document.getElementById("managerInput").value = Element.Manager;
    document.getElementById("farmtypeInput").value = Element.FarmType;
  }

  function YesUpdate() {
    const Shed = {
      id: document.getElementById("idInput").value,
      Name: document.getElementById("nameInput").value,
      Location: document.getElementById("locationInput").value,
      Manager: document.getElementById("managerInput").value,
      FarmType: document.getElementById("farmtypeInput").value
    };
    fetch('http://localhost:7000/Shed/Update-Shed', {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(Shed)
    }).then(resp => resp.json()).then(info => console.log());

    toast.success('Shed is Updated Successfully!', {
      position: "top-center",
      autoClose: 500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    setTimeout(() => {
      document.getElementById("ShedRow").classList.remove("d-none");
      document.getElementById("UpdateFormRow").classList.add("d-none");

      setTimeout(() => {
        window.location.reload();
      }, "10");

    }, "1500");
  }

  function BacktoFrontPage() {
    Navigate('/Dashboard');
  }

  function ViewDetails(Element) {
    document.getElementById("DetailsRow").classList.remove("d-none");

    for (let i = 0; i < Expense.length; i++) {
      if (Expense[i].shedName === Element.Name) {
        particularShedExpense += Expense[i].price;
      }
    }
    for (let i = 0; i < Income.length; i++) {
      if (Income[i].shedName === Element.Name) {
        particularShedIncome += Income[i].price;
      }
    }
    setShedExpense(particularShedExpense.toLocaleString());
    setShedIncome(particularShedIncome.toLocaleString());
    setShedName(Element.Name)
  }

  useEffect(() => {
    fetch("http://localhost:7000/Shed")
      .then((resp) => resp.json())
      .then((info) => setSheds(info));

    fetch("http://localhost:7000/Expense")
      .then((resp) => resp.json())
      .then((info) => setExpense(info));

    fetch("http://localhost:7000/Income")
      .then((resp) => resp.json())
      .then((info) => setIncome(info));
  }, []);
  return (
    <>
      <div className='container-fluid'>
        {/* Spacing Row */}
        <div className='row text-center' id="MyShedsHeading">
          <div className='col-md-12'>
            <h2><b>MY SHEDS</b></h2>
          </div>
        </div>

        <div className='row mt-5 d-none' id="UpdateFormRow">
          <div className='col-md-2'></div>
          <div className='col-md-8'>
            <div className='form shadow'>
              <div className='container'>
                <div className='row mt-5'></div>
                {/* Card Heading */}
                <div className='row mt-5'>
                  <div className='col-md-12 text-center'>
                    <h3><b>UPDATE SHED</b></h3>
                  </div>
                </div>

                {/* ID */}
                <div className='row mt-3'>
                  <div className='col-md-1'></div>
                  <div className='col-md-10'>
                    <label htmlFor="idInput">Id#</label>
                    <input type='number' className='form-control' placeholder='Enter ID' id='idInput' readOnly />
                  </div>
                </div>

                {/* Title */}
                <div className='row mt-3'>
                  <div className='col-md-1'></div>
                  <div className='col-md-10'>
                    <label htmlFor="nameInput">Shed Name</label>
                    <input className='form-control' placeholder='Enter Name' id='nameInput' autoComplete='off' />
                  </div>
                </div>

                {/* Location */}
                <div className='row mt-3'>
                  <div className='col-md-1'></div>
                  <div className='col-md-10'>
                    <label htmlFor="locationInput">Location</label>
                    <input className='form-control' placeholder='Enter Location' id='locationInput' autoComplete='off' />
                  </div>
                </div>

                {/* Manager */}
                <div className='row mt-3'>
                  <div className='col-md-1'></div>
                  <div className='col-md-10'>
                    <label htmlFor="managerInput">Manager</label>
                    <input className='form-control' placeholder='Enter Manager' id='managerInput' autoComplete='off' />
                  </div>
                </div>

                {/* Type */}
                <div className='row mt-3'>
                  <div className='col-md-1'></div>
                  <div className='col-md-10'>
                    <label htmlFor="farmtypeInput">Farm Type</label>
                    <input className='form-control' placeholder='Enter Farm Type' id='farmtypeInput' autoComplete='off' />
                  </div>
                </div>

                {/* Submit Button */}
                <div className='row mt-4 mb-4'>
                  <div className='col-md-12 text-center'>
                    <button id="SubmitButton" onClick={YesUpdate}>Update</button>
                  </div>
                </div>
                <div className='row mt-5'></div>
              </div>
            </div>
          </div>
          <div className='col-md-2'></div>
        </div>

        {/* Sheds */}
        <div className="row" id="ShedRow">
          <div className='col-md-2'></div>
          <div className="col-md-8">
            <div className='row mt-5'></div>
            <div className="form shadow">
              <div className='container'>

                {/* Upper Sheds */}
                <div className="row my-5">
                  {
                    Sheds.map((Element) => {
                      return (
                        <div className='col-md-4 text-center'>
                          <div className='form my-5 shadow' id="Shed1">
                            <h6 className='mt-3 text-center'><b>{Element.Name}</b></h6>
                            <img src={Shed4Picture} id="ShedPicture" className='mb-3' alt='' />
                            <h6 className='mb-3'><FontAwesomeIcon icon={faEgg} /> {Element.id} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <FontAwesomeIcon icon={faLocationDot} /> {Element.Location}</h6>
                            <button id='DetailsButton' onClick={() => ViewDetails(Element)}>Expense/Income Record</button>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>

                <div className='row d-none' id="DetailsRow">
                  <div className='col-md-12 text-center'>
                    <div className='table-responsive'>
                      <table className='table table-hover table-bordered'>
                        <thead>
                          <tr>
                            <th>Shed Name</th>
                            <th>Expense</th>
                            <th>Income</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{ShedName}</td>
                            <td>{ShedExpense}</td>
                            <td>{ShedIncome}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className='col-md-2'></div>
        </div>

        <div className='row mt-5'>
          <div className='col-md-1'></div>
          <div className='col-md-10'>
            <div className='table-responsive'>
              <table className='table table-bordered table-hover text-center'>
                <thead>
                  <tr>
                    <th colSpan='7'>
                      <p>SHED DETAILS</p>
                    </th>
                  </tr>
                  <tr>
                    <th>Id#</th>
                    <th>Name</th>
                    <th>Location</th>
                    <th>Manager</th>
                    <th>Farm Type</th>
                    {
                      (UserName === "Admin") ?
                        <th>Operations</th> : <th className='d-none'></th>
                    }
                  </tr>
                </thead>
                <tbody>
                  {Sheds.map((Shed) => {
                    return (
                      <tr>
                        <td>{Shed.id}</td>
                        <td>{Shed.Name}</td>
                        <td>{Shed.Location}</td>
                        <td>{Shed.Manager}</td>
                        <td>{Shed.FarmType}</td>
                        {
                          (UserName === "Admin") ?
                            <td className='text-center'>
                              <button id="UpdateButton" onClick={() => EditShed(Shed)}>Edit</button>
                              <button id="DeleteButton" onClick={() => DeleteShed(Shed.id)}>Delete</button>
                              <ToastContainer />
                            </td> : <td className='d-none'></td>
                        }
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className='col-md-1'></div>
        </div>

        {/* Submit Button */}
        <div className='row my-2'>
          <div className='col-md-12 text-center'>
            <button id="BackButton" onClick={BacktoFrontPage}><FontAwesomeIcon icon={faHome} /> Home</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default GetSheds