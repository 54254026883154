import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ThemeComp from './ThemeComp';
import { toPascalCase } from './assets/GlobleFun';

function Protected(props) {
  let Navigate = useNavigate();
  let UserName = toPascalCase(sessionStorage.getItem("UserName"));

  useEffect(() => {
    if (UserName === "" || UserName === null) {
      Navigate("/login");
    }
    else {

    }
  })

  return (
    <>
      <ThemeComp />
      <div className='container-fluid px-5' style={{ marginTop: "110px" }}>
        <div className='row'>
          <h3>👋 Welcome, {UserName}</h3>
        </div>
        {props.Component}
      </div>
    </>
  )
}

export default Protected;