import React, { useEffect, useState } from 'react';


import './MainPage.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEgg, faLocationDot } from '@fortawesome/free-solid-svg-icons';

import Shed4Picture from '../Pictures/Shed3Picture.jpg';

function MainPage() {

  const [Sheds, setSheds] = useState([]);
  const [Expense, setExpense] = useState([]);
  const [Income, setIncome] = useState([]);

  const [ID, setShedID] = useState([]);
  const [Name, setShedName] = useState([]);
  const [Location, setShedLocation] = useState([]);
  const [Manager, setShedManager] = useState([]);
  const [FarmType, setShedFarmType] = useState([]);
  const [ShedExpense, setShedExpense] = useState([]);
  const [ShedIncome, setShedIncome] = useState([]);

  var particularShedExpense = 0;
  var particularShedIncome = 0;

  function CardDetails(Element) {
    document.getElementById("CardDetailsTable").classList.remove("d-none");
    setShedID(Element.id);
    setShedName(Element.Name);
    setShedLocation(Element.Location);
    setShedManager(Element.Manager);
    setShedFarmType(Element.FarmType);
    Money(Element.Name);
  }

  function Money(ShedName) {

    for (let i = 0; i < Expense.length; i++) {
      if (Expense[i].shedName === ShedName) {
        particularShedExpense += Expense[i].price;
      }
    }
    
    for (let i = 0; i < Income.length; i++) {
      if (Income[i].shedName === ShedName) {
        particularShedIncome += Income[i].price;
      }
    }

    setShedExpense(particularShedExpense.toLocaleString())
    setShedIncome(particularShedIncome.toLocaleString())
  }

  useEffect(() => {
    fetch("http://localhost:7000/Shed")
      .then((resp) => resp.json())
      .then((info) => setSheds(info));

    fetch("http://localhost:7000/Expense")
      .then((resp) => resp.json())
      .then((info) => setExpense(info));

    fetch("http://localhost:7000/Income")
      .then((resp) => resp.json())
      .then((info) => setIncome(info));
  }, []);

  return (
    <>
      {/* Sheds */}

        <div className="row justify-content-start mt-3 " id="ShedRow">
          <div className="col-md-6">
            <div className="form shadow C-Border rounded">
              <div className='container'>

                <div className="row">
                  {
                    Sheds.map((Element) => {
                      return (
                        <div className='col-md-6 text-center'>
                          <div className='form my-5 shadow C-Border rounded' id="Shed1">
                            <h6 className='mt-3 text-center'><b>{Element.Name}</b></h6>
                            <img src={Shed4Picture} id="ShedPicture" className='mb-3' alt='' />
                            <h6 className='mb-3'><FontAwesomeIcon icon={faEgg} /> {Element.id} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <FontAwesomeIcon icon={faLocationDot} /> {Element.Location}</h6>
                            <p className='text-center'><button id="ViewButton" onClick={() => CardDetails(Element)}>View</button></p>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
                <div className='row d-none' id="CardDetailsTable">
                  <div className='col-md-12 table-responsive'>
                    <table className='table text-center table-bordered table-hover'>
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>Shed Name</th>
                          <th>Location</th>
                          <th>Manager</th>
                          <th>Farm Type</th>
                          <th>Expense</th>
                          <th>Income</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{ID}</td>
                          <td>{Name}</td>
                          <td>{Location}</td>
                          <td>{Manager}</td>
                          <td>{FarmType}</td>
                          <td>{ShedExpense}</td>
                          <td>{ShedIncome}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default MainPage