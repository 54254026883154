import React from 'react'

import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { PoultryFarmState, ShedsState, defaultApiUrl } from '../../Atom';
import { useRecoilState, useRecoilValue } from 'recoil';

const AddShedForm = (props) => {
    let defaultApi = useRecoilValue(defaultApiUrl);
    let PoultryFarms = useRecoilValue(PoultryFarmState);
    const [ShedsData, setShedsData] = useRecoilState(ShedsState);

    let Navigate = useNavigate();

    let initialValues = {
        "poultryFarm_FK": "",
        "name": "",
        // "area": "",
        "status": "",
    }

    let validationSchema = Yup.object({
        poultryFarm_FK: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        // area: Yup.string().required('Required'),
        // status: Yup.string().required('Required')
    })

    function SubmitShed(values,{resetForm}) {
        // check this 
        const Shed = {
            poultryFarm_FK: values.poultryFarm_FK,
            name: values.name,
            // area: values.area,
            status: "vacant",
            user_FK: JSON.parse(sessionStorage.getItem("UserData"))._id
        };

        console.log(JSON.stringify(Shed));

        axios.post(defaultApi + '/api/shed/Add', Shed).then((res) => {
            toast.success('Shed is added Successfully!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });


            console.log(res.data);

            setShedsData((oldShedsData) => [...oldShedsData, res.data.addShed]);

            if (props.open) {
                props.setOpen(false);
            }
            resetForm();
            setTimeout(() => {
                Navigate('/Show-Sheds');
            }, "1500")

        }).catch((error) => {
            toast.error(error.status, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        });


    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={SubmitShed}
            innerRef={props.formRef}  // Attach the formRef to Formik's innerRef
        >
            {({ values }) => {

                return (
                    <Form>
                        <div className='row mt-4'>
                            <div className='col-md-12 text-center'>
                                <h3><b>ADD NEW SHED</b></h3>
                            </div>
                        </div>
                        <div className='row mt-3 justify-content-center'>
                            <div className='col-md-10'>
                                <label htmlFor="nameInput">Name</label>
                                <Field className='form-control' placeholder='Enter Name' id='nameInput' name="name" autoComplete='off' />
                                <ErrorMessage name='name' className='text-danger' component={"div"} />
                            </div>
                        </div>
                        {/** 
                        Shed Area is Removed From Backend Because of New Change
                        <div className='row mt-3 justify-content-center'>
                            <div className='col-md-10'>
                                <label htmlFor="locationInput">Area</label>
                                <Field className='form-control' placeholder='Enter Location' id='locationInput' name="area" autoComplete='off' />
                                <ErrorMessage name='area' className='text-danger' component={"div"} />
                            </div>
                        </div>
                        */}

                        <div className='row my-3 justify-content-center'>
                            <div className='col-md-10'>
                                <label htmlFor="poultryFarm_FK">Poultry Farm</label>
                                <Field className='form-select' id='poultryFarm_FK' name="poultryFarm_FK" autoComplete='off' as="Select" >
                                    <option value={""}> Select </option>
                                    {PoultryFarms.map((elem, index) => {
                                        return (
                                            <option key={index} selected={PoultryFarms.length === 1 && true} value={elem._id}> {elem.name} </option>
                                        )
                                    })}
                                </Field>
                                <ErrorMessage name='poultryFarm_FK' className='text-danger' component={"div"} />
                            </div>
                        </div>
                        {/**
                        <div className='row my-3 justify-content-center'>
                            <div className='col-md-10'>
                                <label htmlFor="farmtypeInput">Status</label>
                                <Field className='form-select' id='status' name="status" autoComplete='off' as="Select" >
                                    <option value={""}> Select </option>
                                    <option value={Occupied}> Occupied  </option>
                                    <option value={Vacant}> Vacant   </option>
                                </Field>
                                <ErrorMessage name='status' className='text-danger' component={"div"} />

                            </div>
                        </div> */}

                        <div className={`row mb-3 ${props.open && 'd-none'} `}>
                            <div className='col-md-12 text-center'>
                                <button id="SubmitButton" onClick={SubmitShed}>Submit</button>
                            </div>
                        </div>
                    </Form>
                )
            }
            }
        </Formik>
    )
}

export default AddShedForm
