import React, { useState } from 'react'
import { useFormik } from 'formik';
import { Link, useNavigate } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

import * as Yup from 'yup';
import "yup-phone";
import axios from 'axios';
import { Form, Image, InputGroup } from 'react-bootstrap';

import { FiEye, FiEyeOff } from "react-icons/fi";

const UserForm = () => {

  var navigate = useNavigate();

  function FrontPage() {
    navigate('/Dashboard');
  }

  let letters = /^[a-zA-Z ]*$/;

  let [passwordSwitch, setPasswordSwitch] = useState(false)
  let [confirmPasswordSwitch, setConfirmPasswordSwitch] = useState(false)


  const formik = useFormik({

    initialValues: {
      "id": 1,
      "userName": "",
      "password": "",
      "CPassword": "",
      "email": "",
      "phoneNumber": "",
      "CNIC": "",
      "address": "",
      "accountType": "2001",
      "imageUrl": ""

    },

    onSubmit: (values, action) => {

      let myObj = {
        id: values.userId,
        userName: values.userName,
        password: values.password,
        email: values.email,
        phone: values.phoneNumber,
        CNIC: values.CNIC,
        address: values.address,
        designation: values.designation
      }
      axios
        .post("http://localhost:7000/User/Add-User", myObj)
        .then((res) => {
          toast.success("user added successfully", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(function () {
            action.resetForm();
            navigate("/user-list")

          }, 2000);


        }
        );
    },

    validationSchema: Yup.object({

      userId: Yup.number().required('Required').test(
        'Is positive?',
        'id must be a positive number',
        (value) => value > 0
      ),
      userName: Yup.string().matches(letters, "name can only contain alphabet letters").min(3).max(20).required('Required'),
      password: Yup.string().min(6).max(20).required('Please enter your password'),
      CNIC: Yup.string().min(13, 'invalid CNIC').max(13, 'invalid CNIC').required('Please enter your CNIC').test(
        'Is positive?',
        'invalid cnic',
        (value) => value > -1
      ),


      email: Yup.string()
        .email('Invalid email format')
        .required('Required'),
      address: Yup.string().required('Required'),
      phoneNumber: Yup.string().phone('pk', true, 'invalid phone number').required('Required'),
      designation: Yup.string().required('Required')

    })


  })
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <ToastContainer />
          <form className='w-100' onSubmit={formik.handleSubmit}>
            <div className='row mt-2'>
              <div className='col-md-12 text-center'>
                <h3><b>Add new User</b></h3>
              </div>
            </div>

            <div className='row mt-2 justify-content-center'>
              <div className='col-md-10'>
                <label htmlFor='userName'>Name</label>
                <input autoComplete='off' className='form-control' type="string" id='userName' placeholder="Enter your name" name="userName"
                  onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.userName} />
                {formik.touched.userName && formik.errors.userName ? <div className='text-danger'>{formik.errors.userName}</div> : null}
              </div>
            </div>

            <div className='row mt-2 justify-content-center'>
              <div className='col-md-10'>
                <label htmlFor='phoneNumber'>Phone</label>

                <input autoComplete='off' className='form-control' type="string" id='phoneNumber' placeholder="03XXXXXXXXX" name="phoneNumber"
                  onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.phoneNumber} />
                {formik.touched.phoneNumber && formik.errors.phoneNumber ? <div className='text-danger'>{formik.errors.phoneNumber}</div> : null}
              </div>
            </div>

            <div className='row mt-2'>
              <div className='col-md-1'></div>
              <div className='col-md-10'>
                <label htmlFor='password'>Password</label>
                <InputGroup>
                  <Form.Control className='form-control box-shadow-0 border-end-0' type={passwordSwitch ? "text" : "password"} id='password' placeholder="Password" name='password'
                    onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.password} />
                  <InputGroup.Text className='bg-white border-start-0 curserPointer' onClick={() => setPasswordSwitch(!passwordSwitch)} > {passwordSwitch ? <FiEye /> : <FiEyeOff />}</InputGroup.Text>
                </InputGroup>

                {formik.touched.password && formik.errors.password ? <div className='text-danger'>{formik.errors.password}</div> : null}
              </div>
              <div className='col-md-1'></div>
            </div>

            <div className='row mt-2'>
              <div className='col-md-1'></div>
              <div className='col-md-10'>
                <label htmlFor='CPassword'>Confirm Password</label>
                <InputGroup>
                  <Form.Control className='form-control box-shadow-0 border-end-0' type={confirmPasswordSwitch ? "text" : "password"} id='CPassword' placeholder="Confirm Password" name='CPassword'
                    onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.CPassword} />
                  <InputGroup.Text className='bg-white border-start-0 curserPointer' onClick={() => setConfirmPasswordSwitch(!confirmPasswordSwitch)} > {confirmPasswordSwitch ? <FiEye /> : <FiEyeOff />}</InputGroup.Text>
                </InputGroup>

                {formik.touched.CPassword && formik.errors.CPassword ? <div className='text-danger'>{formik.errors.CPassword}</div> : null}
              </div>
              <div className='col-md-1'></div>
            </div>

            <div className='row mt-3 mb-2 justify-content-center '>
              <div className='col-md-10'>
                <p className='p-0 m-0'>I already have an Account <Link to='/login' className='C-color'>Login</Link></p>
              </div>
            </div>

            <div className='row justify-content-center '>
              <div className='col-md-10'>
                <button type='submit' className='ButtonPrimary w-100 '>Sign up</button>
              </div>
            </div>

          </form>

        </div>
        <div className="col-md-2">
          <div className='row my-3'>
            <div className='col-md-12 text-center'>
              <button id="SubmitButton" onClick={FrontPage}><FontAwesomeIcon icon={faHome} /> Home</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserForm