import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "yup-phone";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';


const UserList = () => {

  let UserName = sessionStorage.getItem("UserName");
  let Navigate = useNavigate();
  function BacktoFrontPage() {
    Navigate('/Dashboard');
  }
  const [uId, setuId] = useState("");
  const [userName, setUserName] = useState("");
  const [pass, setpass] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [cnic, setcnic] = useState("");
  const [address, setaddress] = useState("");
  const [designation, setdesignation] = useState("");




  const [listOfUsers, setListOfUsers] = useState([])

  const updateHandle = (elem) => {

    setUserName(document.getElementById("userName").value = elem.userName);
    setuId(document.getElementById("userId").value = elem.id);
    setpass(document.getElementById("password").value = elem.password);
    setemail(document.getElementById("email").value = elem.email);
    setphone(document.getElementById("phoneNumber").value = elem.phone);
    setcnic(document.getElementById("CNIC").value = elem.CNIC);
    setaddress(document.getElementById("address").value = elem.address);
    setdesignation(document.getElementById("designation").value = elem.designation);


    document.getElementById("formCol").classList.remove("d-none");

  }

  const handleSubmit = (e) => {
    e.preventDefault();



    let myObj = {
      id: uId,
      userName: userName,
      password: pass,
      email: email,
      phone: phone,
      CNIC: cnic,
      address: address,
      designation: designation
    }

    axios
      .patch(`http://localhost:7000/User/Update-User/${uId}`, myObj)
      .then((res) => {
        document.getElementById("formCol").classList.add("d-none");


        toast.success("user updated successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        window.location.reload();
      });
  }

  const deleteHandle = (index, userName) => {
    if (window.confirm("Are you sure to want to delete " + userName)) {
      var obj = index;
      axios
        .delete(`http://localhost:7000/User/Delete-User/${obj}`)
        .then((res) => { console.log() }
        );

      toast.error("user deleted successfully", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      window.location.reload();
    }
  }

  useEffect(() => {
    axios
      .get("http://localhost:7000/User")
      .then((res) => {
        setListOfUsers(res.data)

      }
      );

  }, [])


  return (
    <>
      <div className="container-fluid">
        <div className='row mb-3 text-center' id="MyShedsHeading">
          <div className='col-md-12'>
            <h2><b>MY USERS</b></h2>
          </div>
        </div>
        <div className="row">
          {/* user form */}
          <div className="col-md-2"></div>
          <div className="col-md-8 d-none " id='formCol' >
            <form className='py-3 border rounded px-3 mt-3 ' onSubmit={handleSubmit}>

              <div className='row mt-4'>
                <div className='col-md-12 text-center'>
                  <h3><b>UPDATE USER</b></h3>
                </div>
              </div>

              {/* user Id  */}
              <div className='row mt-3'>
                <div className='col-md-1'></div>
                <div className='col-md-10'>
                  <label htmlFor='userId' >User Id</label>
                  <input readOnly type="number" className="form-control py-1" id="userId"
                    name="userId" value={uId} onChange={(e) => setuId(e.target.value)} />
                </div>
                <div className='col-md-1'></div>
              </div>

              {/* user name */}
              <div className='row mt-3'>
                <div className='col-md-1'></div>
                <div className='col-md-10'>
                  <label htmlFor='userName' >User Name</label>
                  <input autoComplete='off' className='form-control py-1' type="text" id='userName'
                    placeholder="user name" name='userName' value={userName} onChange={(e) => setUserName(e.target.value)} />
                </div>
                <div className='col-md-1'></div>
              </div>

              {/* password */}
              <div className='row mt-3'>
                <div className='col-md-1'></div>
                <div className='col-md-10'>
                  <label htmlFor='password'>Password</label>
                  <input className='form-control' type="password" id='password' placeholder="password"
                    name='password' value={pass} onChange={(e) => setpass(e.target.value)} />
                </div>
                <div className='col-md-1'></div>
              </div>

              {/* email */}
              <div className='row mt-3'>
                <div className='col-md-1'></div>
                <div className='col-md-10'>
                  <label htmlFor='email'>Email address</label>
                  <input autoComplete='off' className='form-control py-1' type="email" id='email'
                    placeholder="abc@example.com" name="email" value={email} onChange={(e) => setemail(e.target.value)} />
                </div>
                <div className='col-md-1'></div>
              </div>

              {/* phoneNumber */}
              <div className='row mt-3'>
                <div className='col-md-1'></div>
                <div className='col-md-10'>
                  <label htmlFor='phoneNumber'>Phone</label>
                  <input autoComplete='off' className='form-control py-1' type="number" id='phoneNumber'
                    placeholder="phone number" name="phoneNumber" value={phone} onChange={(e) => setphone(e.target.value)} />
                </div>
                <div className='col-md-1'></div>
              </div>

              {/* CNIC */}
              <div className='row mt-3'>
                <div className='col-md-1'></div>
                <div className='col-md-10'>
                  <label htmlFor='CNIC'>CNIC</label>
                  <input autoComplete='off' className='form-control py-1' type="number" id='CNIC'
                    placeholder="3540285906801" name="CNIC" value={cnic} onChange={(e) => setcnic(e.target.value)} />
                </div>
                <div className='col-md-1'></div>
              </div>

              {/* Address */}
              <div className='row mt-3'>
                <div className='col-md-1'></div>
                <div className='col-md-10'>
                  <label htmlFor='address'>Address</label>
                  <input autoComplete='off' className='form-control py-1' type="text" id='address'
                    placeholder="address" name="address" value={address} onChange={(e) => setaddress(e.target.value)} />
                </div>
                <div className='col-md-1'></div>
              </div>

              {/* designation */}
              <div className='row mt-3'>
                <div className='col-md-1'></div>
                <div className='col-md-10'>
                  <label htmlFor='designation' >Designation</label>
                  <input autoComplete='off' className='form-control value="" py-1' type="text" id='designation'
                    placeholder="designation" name='designation' value={designation} onChange={(e) => setdesignation(e.target.value)} />
                </div>
                <div className='col-md-1'></div>
              </div>

              <div className='row mt-3'>
                <div className='col-md-12 text-center'>
                  <button type='submit' id="SubmitButton">Update</button>
                  <ToastContainer />
                </div>
              </div>
            </form>

          </div>
          <div className="col-md-2"></div>
          {/* user form */}

          {/* table */}
          <div className='row mt-5'>
            <div className='col-md-12'>
              <div className='table-responsive'>
                <table className="table table-bordered table-hover text-center">
                  <thead>
                    <tr>
                      <th colSpan='9'>User Details</th>
                    </tr>
                    <tr>
                      <th>Id</th>
                      <th>Username</th>
                      <th>Password</th>
                      <th>Email</th>
                      <th>Phone#</th>
                      <th>CNIC</th>
                      <th>Address</th>
                      <th>Designation</th>
                      {
                        (UserName === "Admin") ?
                          <th>Operations</th> : <th className='d-none'></th>
                      }

                    </tr>
                  </thead>

                  <tbody>
                    {
                      listOfUsers.map((elem) => {
                        let { id, userName, password, email, phone, CNIC, address, designation } = elem;
                        return (
                          <tr key={id}>
                            <td>{id}</td>
                            <td>{userName}</td>
                            <td>{password}</td>
                            <td>{email}</td>
                            <td>{phone}</td>
                            <td>{CNIC}</td>
                            <td>{address}</td>
                            <td>{designation}</td>
                            {
                              (UserName === "Admin") ?
                                <td >
                                  <button id="UpdateButton" onClick={() => updateHandle(elem)}>Edit</button>
                                  <button id="DeleteButton" onClick={() => deleteHandle(id, userName)}>Delete</button>
                                </td> : <td className='d-none'></td>
                            }
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className='row my-2'>
          <div className='col-md-12 text-center'>
            <button id="BackButton" onClick={BacktoFrontPage}><FontAwesomeIcon icon={faHome} /> Home</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserList