import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useRecoilState, useRecoilValue } from "recoil";
import {
  Inventory,
  PoultryFarmState,
  UpdateHandle,
  allFlocks,
  allUsers,
  antDCustomTheme,
  defaultApiUrl,
  incomes,
} from "../../Atom";
import DynamicAntTable from "../assets/DynamicAntTable";
import { Card } from "react-bootstrap";
import { Button, ConfigProvider, Dropdown, Space } from "antd";

import { IoSettingsOutline } from "react-icons/io5";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { GrUpdate } from "react-icons/gr";
import { MdOutlineLibraryAdd } from "react-icons/md";
import { convertDateToPakistaniFormat, userData } from "../assets/GlobleFun";
import { toComma, toCaps } from "../../GlobalFunctions";

// import toCaps from "../../GlobalFunctions";

export function IncomeListTable() {
  let defaultApi = useRecoilValue(defaultApiUrl);

  let Income = useRecoilValue(incomes);
  let allFlock = useRecoilValue(allFlocks);
  let allUsersObj = useRecoilValue(allUsers);

  let [incomeObj, setIncomes] = useRecoilState(incomes);
  const [selectedRow, setSelectedRow] = useState(null);
  const [updateHandle, setUpdateHandle] = useRecoilState(UpdateHandle);
  let inventory = useRecoilValue(Inventory);

  useEffect(() => {
    console.log("incomes", Income);
  }, []);

  let deleteHandler = (elem) => {
    if (window.confirm("Are you sure to want to delete this income")) {
      var obj = elem._id;
      axios
        .delete(defaultApi + "/api/income/delete/" + obj)
        .then((res) => {
          console.log(res);

          // Filter out the deleted item from the Recoil state array
          setIncomes((prevIncomes) =>
            prevIncomes.filter((income) => income._id !== obj)
          );

          toast.error("Income deleted successfully", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong please try again later", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }
  };

  let poultryFarm = useRecoilValue(PoultryFarmState);
  // Custom rendering function for columns
  const renderInventoryName = (record) => {
    let inventoryId = record;

    // console.log("poultryFarmId", poultryFarmId)

    inventoryId = inventory.map((elem, index) => {
      if (inventoryId === elem._id) {
        // console.log(elem.inventoryType)
        // if (elem.inventoryType === "soldIn") {
        //   // console.log(elem.flock_FK)
        //   return (elem.flock_FK.flockType);
        // }
        // else
        if (
          elem.inventoryType === "flockIn" ||
          elem.inventoryType === "soldIn"
        ) {
          // alert("kuch huwa")
          // console.log("el3m1",allFlock);
          let foundData = "";
          allFlock.map((flock, index) => {
            if (elem.flock_FK == flock._id) {
              // console.log("el3m", elem);
              foundData = flock.flockType;
            }
          });
          return toCaps(foundData) || "inventory lost Please Check Records";
        } else {
          console.log("el3m1", allFlock);
          return toCaps(elem.name);
        }
      }
    });

    return <strong>{inventoryId}</strong>; // Return custom value or original ID
  };

  // Custom rendering function for columns
  const renderPoultryName = (record) => {
    let poultryFarmId = record;

    // console.log("poultryFarmId", poultryFarmId)

    poultryFarmId = poultryFarm.map((elem, index) => {
      if (poultryFarmId === elem._id) {
        return elem.name;
      }
    });

    return poultryFarmId; // Return custom value or original ID
  };

  const items =
    JSON.parse(sessionStorage.getItem("UserData")).accountType === "5156"
      ? [
        {
          key: "1",
          label: "Add New",
          onClick: () => handleMenuClick("1", selectedRow),
          icon: <MdOutlineLibraryAdd />,
        },
        {
          key: "2",
          label: "Update",
          onClick: () => handleMenuClick("2", selectedRow),
          icon: <GrUpdate />,
        },
        {
          key: "3",
          danger: true,
          label: "Delete",
          icon: <RiDeleteBin6Line />,
          onClick: () => handleMenuClick("3", selectedRow),
        },
      ]
      : [
        {
          key: "1",
          label: "Add New",
          onClick: () => handleMenuClick("1", selectedRow),
          icon: <MdOutlineLibraryAdd />,
        },
      ];

  let navigate = useNavigate();

  const handleMenuClick = (key, record) => {
    setSelectedRow(record);
    if (key === "1") {
      navigate("/add-income");
    } else if (key === "2") {
      // Handle update action
      console.log("Update clicked for:", record);
      setUpdateHandle(record);
      navigate("/add-income#update");
    } else if (key === "3") {
      // Handle delete action
      deleteHandler(record);
    }
  };

  const columns = [
    {
      title: "Poultry Farm",
      dataIndex: "poultryFarm_FK",
      key: "poultryFarm_FK",
      ellipsis: false,
      render: renderPoultryName, // Custom render function to display mapped values
    },
    {
      title: "Inventory Name",
      dataIndex: "inventory_FK",
      key: "inventory_FK",
      ellipsis: false,
      render: renderInventoryName, // Custom render function to display mapped values
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (data, record) => {
        // alert(data);

        return <strong>{toComma(data)}</strong>;
      },
      ellipsis: false,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (data, record) => {
        // alert(data);

        return <strong>{toComma(data)}</strong>;
      },
      ellipsis: false,
    },
    {
      title: "Added By",
      dataIndex: "user_FK",
      key: "user_FK",
      ellipsis: false,
      render: (data, record) => {
        // alert(data);
        return (
          userData(data, allUsersObj) +
          " " +
          convertDateToPakistaniFormat(record.updatedAt)
        );
      },
    },

    {
      title: "Options",
      key: "options",
      render: (_, record) => (
        <Dropdown
          menu={{
            items: items.map((item) => ({
              ...item,
              onClick: () => handleMenuClick(item.key, record),
            })),
          }}
          placement="bottom"
          arrow={{
            pointAtCenter: true,
          }}
        >
          <p className="w-50 text-center">
            <IoSettingsOutline size={"20px"} />
          </p>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className="col-md-12">
      <DynamicAntTable column={columns} TData={Income.slice().reverse()} />
    </div>
  );
}

export const IncomeList = () => {
  let Income = useRecoilValue(incomes);
  let navigate = useNavigate();
  let customTheme = useRecoilValue(antDCustomTheme);

  return (
    <div className="container-fluid">
      <div className="row justify-content-center mt-5">
        <div className="col-md-10">
          <Card className="C-Border shadow px-4">
            <div className="row">
              <div className="col-md-12 my-3">
                <h4
                  className="h4 C-color"
                  onClick={() => {
                    console.log(Income);
                  }}
                >
                  <b>My Income History</b>
                  <ConfigProvider theme={customTheme}>
                    <Button
                      type="primary"
                      className="float-end px-5 "
                      onClick={() => {
                        navigate("/add-income");
                      }}
                    >
                      Add New Income +
                    </Button>
                  </ConfigProvider>
                </h4>
              </div>
              <IncomeListTable />
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default IncomeList;
