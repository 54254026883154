import React, { useState } from 'react';
import { Button, Space, Table } from 'antd';
const data = [
    {
        key: '1',
        name: 'John Brown',
        age: 32,
        address: 'New York No. 1 Lake Park',
    },
    {
        key: '2',
        name: 'Jim Green',
        age: 42,
        address: 'London No. 1 Lake Park',
    },
    {
        key: '3',
        name: 'Joe Black',
        age: 32,
        address: 'Sydney No. 1 Lake Park',
    },
    {
        key: '4',
        name: 'Jim Red',
        age: 32,
        address: 'London No. 2 Lake Park',
    },
];
const DynamicAntTable = ({ column, handleChange, TData }) => {

    return (
        <div>
            <Table columns={column} dataSource={TData} onChange={handleChange} />
        </div>
    );
};
export default DynamicAntTable;