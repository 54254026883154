import React from 'react'
import { Card } from 'react-bootstrap';

const CardFromTheme = (props) => {

  return (
    <div className="container">
      <div className="row mt-5 justify-content-center">
        <div className="col-md-8">
          <Card className='shadow C-Border mb-4'>
            <Card.Body>
              {props.children ? (
                React.cloneElement(props.children)
              ) : null}
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default CardFromTheme