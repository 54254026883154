import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './ToolBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCaretDown, faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb } from 'react-bootstrap';
import { toSentenceCase } from './assets/GlobleFun';

import CustomDropDowns from './assets/CustomDropDowns';


function ToolBar({ openSideBar }) {
  let Navigate = useNavigate();
  function LogOutFunction() {
    Navigate('/login');
    sessionStorage.clear();
  }

  let [breadcrumb, setBreadcrumb] = useState("");

  const location = useLocation();
  useEffect(() => {

    const currentRoute = location.pathname;
    setBreadcrumb(toSentenceCase(currentRoute.replace('/', "").replace("-", " ")))

  }, [location.pathname])

  const items = [
    {
      label: <Link to="/ViewProfile" className='C-color2 text-decoration-none fw-bold'>View Profile</Link>,
      key: '0',
    },
    {
      type: 'divider',
    },
    {
      label: <Link to="#" onClick={LogOutFunction} className='text-danger fw-bold text-decoration-none'>Logout</Link>,
      key: '1',
    }
  ];

  return (
    <div className='position-fixed w-100 top-0  px-3 py-2 ' style={{ zIndex: "1" }}>
      <div className={` topBar d-flex flex-column flex-md-row justify-content-start md:justify-content-start align-items-start md:align-items-center  px-md-3 py-md-2 rounded`}>

        <div className=' ms-2 d-flex flex-column'>
          <Breadcrumb className={` BreadcrumbCustom p-0 m-0 `}>
            <Breadcrumb.Item href="#" className='p-0 m-0 brm-hover ' onClick={() => { Navigate('/'); }} active>Dashboard</Breadcrumb.Item>
            {breadcrumb &&
              <Breadcrumb.Item className='p-0 m-0' active>{breadcrumb}</Breadcrumb.Item>}
          </Breadcrumb>
          <div className='Page'>Poultry Farm!</div>
        </div>

        <div className='ms-md-auto  d-flex align-items-center justify-content-around rightBlock'>
          <div className='navbar curserPointer' onClick={openSideBar}>
            <FontAwesomeIcon icon={faBars} style={{fontSize:"25px"}} />
          </div>

          <CustomDropDowns items={items} placement="bottomRight" >
            <div className='d-flex justify-content-center align-items-center gap-2 C-color2 curserPointer'>
              <FontAwesomeIcon icon={faCircleUser}  style={{fontSize:"35px"}}  />
              <FontAwesomeIcon icon={faCaretDown} style={{fontSize:"15px"}} />
            </div>
          </CustomDropDowns>

        </div>
      </div>
    </div>

  )
}

export default ToolBar