import React, { useState } from 'react'
import CustomCard from '../assets/CustomCard'
// import DynamicTable from '../assets/DynamicTable';
import DynamicAntTable from '../assets/DynamicAntTable';
import AddNew from '../AddNew/AddNew';
import IncomeForm from '../Forms/IncomeForm';

const Income = (props) => {


    // const [filteredInfo, setFilteredInfo] = useState({}); // eslint-disable-line no-unused-vars

    // const [sortedInfo, setSortedInfo] = useState({});

    // const columns = [
    //     {
    //         title: 'Company Name',
    //         dataIndex: 'companyName',
    //         key: 'companyName',
    //         ellipsis: true,
    //     },
    //     {
    //         title: 'Source',
    //         dataIndex: 'source',
    //         key: 'source',
    //         sorter: (a, b) => a.source - b.source,
    //         sortOrder: sortedInfo.columnKey === 'source' ? sortedInfo.order : null,
    //         ellipsis: true,
    //     },
    //     {
    //         title: 'Quantity',
    //         dataIndex: 'quantity',
    //         key: 'quantity',
    //         sorter: (a, b) => a.quantity - b.quantity,
    //         sortOrder: sortedInfo.columnKey === 'quantity' ? sortedInfo.order : null,
    //         ellipsis: true,
    //     },
    //     {
    //         title: 'Total Price',
    //         dataIndex: 'price',
    //         key: 'price',
    //     },
    //     {
    //         title: 'Paid Amount',
    //         dataIndex: 'paidAmount',
    //         key: 'paidAmount',
    //     },
    //     {
    //         title: 'Due Amount',
    //         dataIndex: 'dueAmount',
    //         key: 'dueAmount',
    //     },
    //     {
    //         title: 'Due Date',
    //         dataIndex: 'dueDate',
    //         key: 'dueDate',
    //         sorter: (a, b) => a.dueDate - b.dueDate,
    //         sortOrder: sortedInfo.columnKey === 'dueDate' ? sortedInfo.order : null,
    //         ellipsis: true,
    //     },
    //     {
    //         title: 'Shed Name',
    //         dataIndex: 'shedName',
    //         key: 'shedName',
    //     },
    //     {
    //         title: 'Added By',
    //         dataIndex: 'addedBy',
    //         key: 'addedBy',
    //     },
    // ];

    // const handleChange = (pagination, filters, sorter) => {
    //     console.log('Various parameters', pagination, filters, sorter);
    //     setFilteredInfo(filters);
    //     setSortedInfo(sorter);
    // };
    return (
        <div>
            <CustomCard extra={'border-0 py-2 px-4 mb-5'}>
                <div className="d-flex justify-content-between align-items-center w-100">
                    <h4 className='h4 fw-bold my-3'>Your Income:</h4>
                    <AddNew btnText={'Add New Income'} type="primary" >
                        <IncomeForm />
                    </AddNew>
                </div>
                {/** <DynamicTable TableHead=s{TableHead} TData={IncomeData} /> 
                <DynamicAntTable column={columns} TData={IncomeData} handleChange={handleChange} />*/}
                {props.comp}

            </CustomCard>
        </div>
    )
}

export default Income
