import React, { useEffect, useState } from "react";
import DynamicAntTable from "../assets/DynamicAntTable";
import { Button, ConfigProvider, Dropdown, Tag } from "antd";
import { Card } from "react-bootstrap";
import { IoSettingsOutline } from "react-icons/io5";
import {
  Inventory,
  PoultryFarmState,
  ShedsState,
  UpdateHandle,
  allFlocks,
  allUsers,
  antDCustomTheme,
  defaultApiUrl,
  incomes,
  reRunApiFlag,
} from "../../Atom";
import { useRecoilState, useRecoilValue } from "recoil";
import { MdOutlineLibraryAdd } from "react-icons/md";
import { GrUpdate } from "react-icons/gr";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { convertDateToPakistaniFormat, userData } from "../assets/GlobleFun";
import { toCaps, toComma } from "../../GlobalFunctions";

const ListInventory = () => {
  let defaultApi = useRecoilValue(defaultApiUrl);

  let inventory = useRecoilValue(Inventory);
  let shedsState = useRecoilValue(ShedsState);
  let allFlock = useRecoilValue(allFlocks);
  let allUsersObj = useRecoilValue(allUsers);

  let [inventories, setInventory] = useRecoilState(Inventory);
  const [selectedRow, setSelectedRow] = useState(null);
  const [updateHandle, setUpdateHandle] = useRecoilState(UpdateHandle);

  let [reRunFlag22, setReRunFlag] = useRecoilState(reRunApiFlag);
  let reRunFlag = useRecoilValue(reRunApiFlag);

  // Re-run data fetching based on reRunFlag changes
  useEffect(() => {
    // console.log("object ", inventories);
    if (reRunFlag) {
      // console.log("object",inventories);
      //Inventory Get ALL
      fetch();
      setReRunFlag(false); // Reset reRunFlag after fetching data
    }
  }, []); // Dependency on reRunFlag

  let fetch = async () => {
    //Inventory Get ALL
    let inventoryData = await axios.get(defaultApi + "/api/inventory/");
    if (inventoryData) {
      console.log("inventoryData.data", inventoryData.data);
      let Data = inventoryData.data;
      setInventory(inventoryData.data);
    }
  };

  let deleteHandler = (elem) => {
    if (window.confirm("Are you sure to want to delete this Inventory")) {
      var obj = elem._id;
      axios
        .delete(defaultApi + "/api/inventory/delete/" + obj)
        .then((res) => {
          console.log(res.data);

          // Filter out the deleted item from the Recoil state array
          setInventory((prevIncomes) =>
            prevIncomes.filter((inventory) => inventory._id !== obj)
          );

          toast.error("Inventory deleted successfully", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong please try again later", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }
  };

  let poultryFarm = useRecoilValue(PoultryFarmState);
  // Custom rendering function for columns
  const renderShedName = (record) => {
    let inventoryId = record;

    inventoryId = shedsState.map((elem, index) => {
      if (inventoryId === elem._id) {
        return elem.name;
      }
    });

    return inventoryId;
  };

  // Custom rendering function for columns
  const renderPoultryName = (record, data) => {
    // console.log("poultryFarm ki id", record, data);

    if (data.inventoryType === "flockIn" || data.inventoryType === "soldIn") {
      let foundData = "";
      allFlock.map((elem, index) => {
        if (data.flock_FK == elem._id) {
          return poultryFarm.map((poultry, index) => {
            if (elem.poultryFarm_FK === poultry._id) {
              foundData = poultry.name;
            }
          });
        }
      });
      // console.log("foundData poultryFarmName: ", foundData);

      return foundData || "id lost Please Check Records";
    } else {
      let poultryFarmId =
        data.inventoryType === "flockIn"
          ? data?.flock_FK?.poultryFarm_FK
          : record;

      // console.log("poultryFarm",poultryFarm);
      poultryFarmId = poultryFarm.map((elem, index) => {
        if (poultryFarmId === elem._id) {
          return elem.name;
        }
      });

      return poultryFarmId; // Return custom value or original ID
    }
  };

  const items2 =
    JSON.parse(sessionStorage.getItem("UserData")).accountType === "5156"
      ? [
        {
          key: "1",
          label: "Add New",
          onClick: () => handleMenuClick("1", selectedRow),
          icon: <MdOutlineLibraryAdd />,
        },
        {
          key: "3",
          danger: true,
          label: "Delete",
          icon: <RiDeleteBin6Line />,
          onClick: () => handleMenuClick("3", selectedRow),
        },
      ]
      : [
        {
          key: "1",
          label: "Add New",
          onClick: () => handleMenuClick("1", selectedRow),
          icon: <MdOutlineLibraryAdd />,
        },
      ];

  const items =
    JSON.parse(sessionStorage.getItem("UserData")).accountType === "5156"
      ? [
        {
          key: "1",
          label: "Add New",
          onClick: () => handleMenuClick("1", selectedRow),
          icon: <MdOutlineLibraryAdd />,
        },
        {
          key: "2",
          label: "Update",
          onClick: () => handleMenuClick("2", selectedRow),
          icon: <GrUpdate />,
        },
        {
          key: "3",
          danger: true,
          label: "Delete",
          icon: <RiDeleteBin6Line />,
          onClick: () => handleMenuClick("3", selectedRow),
        },
      ]
      : [
        {
          key: "1",
          label: "Add New",
          onClick: () => handleMenuClick("1", selectedRow),
          icon: <MdOutlineLibraryAdd />,
        },
      ];

  let navigate = useNavigate();

  const handleMenuClick = (key, record) => {
    console.log("selected record", record);


    setSelectedRow(record);
    if (key === "1") {
      navigate("/add-inventory");
    } else if (key === "2") {
      // Handle update action
      console.log("Update clicked for:", record.inventoryType);
      if (record.inventoryType !== "purchased" && record.inventoryType !== "soldIn") {

        setUpdateHandle(record);
        navigate("/add-inventory#update");
      }
      else {
        toast.error("You cannot update this data", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }

    } else if (key === "3") {
      // Handle delete action

      deleteHandler(record);
    }

  };

  const renderInventoryTypeTag = (type, quantity) => {
    if (quantity === 0) {
      return <Tag color="red">Sold Out</Tag>;
    }

    switch (type) {
      case "shedIn":
        return <Tag color="cyan">Available for Sale</Tag>;
      case "purchased":
        return <Tag color="blue">Farm Use Only</Tag>;
      case "flockIn":
        return <Tag color="green">Ready to Sale</Tag>;
      case "soldIn":
        return <Tag color="red">Sold Out</Tag>;
      default:
        return <Tag color="gray">Unknown Type</Tag>;
    }
  };

  let FindQuantity = (value, record) => {
    let foundData = "";
    allFlock.map((elem, index) => {
      if (record.flock_FK == elem._id) {
        // console.log(elem.availableQuantity)
        foundData = elem.availableQuantity;
      }
    });
    // console.log("foundData poultryFarmName: ", foundData);

    return (
      <strong>{toComma(foundData)}</strong> ||
      "Quantity lost Please Check Records"
    );
  };

  let findUserFK = (data, record) => {
    let foundData = "";
    allFlock.map((elem, index) => {
      if (record.flock_FK == elem._id) {
        // console.log(elem.availableQuantity)
        foundData = elem.user_FK;
      }
    });

    return (
      userData(foundData, allUsersObj) +
      " " +
      convertDateToPakistaniFormat(record.updatedAt)
    );
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: false,
      render: (value, record) => {
        // console.log("Records inventory", record);
        if (
          record.inventoryType === "flockIn" ||
          record.inventoryType === "soldIn"
        ) {
          let foundData = "";
          allFlock.map((elem, index) => {
            if (record.flock_FK == elem._id) {
              // console.log(elem)
              foundData = elem.flockType;
            }
          });
          // console.log("foundData poultryFarmName: ", foundData);

          return (
            <strong>{toCaps(foundData)}</strong> ||
            "Quantity lost Please Check Records"
          );
        } else {
          return <strong>{toCaps(value)}</strong>;
        }
      },
    },
    {
      title: "Poultry Farm",
      dataIndex: "poultryFarm_FK",
      key: "poultryFarm_FK",
      ellipsis: false,
      render: renderPoultryName,
    },
    {
      title: "Shed Name",
      dataIndex: "shed_FK",
      key: "shed_FK",
      ellipsis: false,
      render: renderShedName,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      ellipsis: false,
      render: (value, record) => {
        if (
          record.inventoryType === "flockIn" ||
          record.inventoryType === "soldIn"
        ) {
          return FindQuantity(value, record);
        } else {
          return <strong>{toComma(value)}</strong>;
        }
      },
    },
    {
      title: "Inventory",
      dataIndex: "inventoryType",
      key: "inventoryType",
      ellipsis: false,
      render: (value, record) => {
        const quantity =
          record.inventoryType === "flockIn"
            ? record?.flock_FK?.availableQuantity
            : record.quantity;
        return renderInventoryTypeTag(value, quantity);
      },
    },
    {
      title: "Added By",
      dataIndex: "user_FK",
      key: "user_FK",
      ellipsis: false,
      render: (data, record) => {
        // alert(data);

        if (
          record.inventoryType === "flockIn" ||
          record.inventoryType === "soldIn"
        ) {
          return findUserFK(data, record);
        } else {
          return (
            userData(data, allUsersObj) +
            " " +
            convertDateToPakistaniFormat(record.updatedAt)
          );
        }
      },
    },
    {
      title: "Options",
      key: "options",
      render: (_, record) => (
        <Dropdown
          menu={{
            items: record.quantity === 0 || record.inventoryType === "flockIn" ? items2.map((item) => ({
              ...item,

              onClick: () => {
                console.log("record", record);
                handleMenuClick(item.key, record);
              },
            })) : items.map((item) => ({
              ...item,

              onClick: () => {
                console.log("record", record);
                handleMenuClick(item.key, record);
              },
            })),
          }}
          placement="bottom"
          arrow={{
            pointAtCenter: true,
          }}
        >
          <p className="w-50 text-center">
            <IoSettingsOutline size={"20px"} />
          </p>
        </Dropdown>
      ),
    },
  ];
  // let navigate = useNavigate();
  let customTheme = useRecoilValue(antDCustomTheme);
  return (
    <div className="container-fluid">
      <div className="row justify-content-center mt-5">
        <div className="col-md-10">
          <Card className="C-Border shadow px-4">
            <div className="row">
              <div className="col-md-12 my-3">
                <h4
                  className="h4 C-color"
                  onClick={() => {
                    console.log(inventory);
                  }}
                >
                  <b>My Inventory Details</b>
                  <ConfigProvider theme={customTheme}>
                    <Button
                      type="primary"
                      className="float-end px-5 "
                      onClick={() => {
                        navigate("/add-inventory");
                      }}
                    >
                      Add New Inventory +
                    </Button>
                  </ConfigProvider>
                </h4>
              </div>
              <div className="col-md-12">
                <DynamicAntTable column={columns} TData={inventory} />
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ListInventory;
