import React, { useState } from "react";
import CustomCard from "../assets/CustomCard";
import DynamicAntTable from "../assets/DynamicAntTable";
import { Button, ConfigProvider, Dropdown } from "antd";
import { IoSettingsOutline } from "react-icons/io5";
import {
    PoultryFarmState,
    UpdateHandle,
    allExpenses,
    allUsers,
    antDCustomTheme,
    defaultApiUrl,
} from "../../Atom";
import { useRecoilState, useRecoilValue } from "recoil";
import { MdOutlineLibraryAdd } from "react-icons/md";
import { GrUpdate } from "react-icons/gr";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import AddNew from "../AddNew/AddNew";
import ExpenseForm from "../Forms/ExpenceForm";
import { convertDateToPakistaniFormat, userData } from "../assets/GlobleFun";
import { toCaps, toComma } from "../../GlobalFunctions";

const ExpenseList = (props) => {

    const [filteredInfo, setFilteredInfo] = useState({}); // eslint-disable-line no-unused-vars
    const [selectedRow, setSelectedRow] = useState(null);

    let customTheme = useRecoilValue(antDCustomTheme);

    const [sortedInfo, setSortedInfo] = useState({});// eslint-disable-line no-unused-vars

    const poultryFarm = useRecoilValue(PoultryFarmState);
    let allUsersObj = useRecoilValue(allUsers)


    const [updateHandle, setUpdateHandle] = useRecoilState(UpdateHandle);// eslint-disable-line no-unused-vars

    let defaultApi = useRecoilValue(defaultApiUrl);
    const [allexpenses, setAllExpense] = useRecoilState(allExpenses);



    let deleteHandler = (elem) => {
        if (window.confirm("Are you sure to want to delete this expense ?")) {
            var obj = elem._id;
            var tableType = elem.tableType;
            console.log(elem.tableType);
            // console.log(obj, allexpenses);

            switch (tableType) {
                case "dailyExpense":
                    axios
                        .delete(defaultApi + "/api/dailyExpense/delete/" + obj)
                        .then((res) => {
                            console.log(res);

                            setAllExpense((prevData) =>
                                prevData.filter((expense) => expense._id !== obj)
                            );

                            toast.success("Daily expense deleted successfully", {
                                position: "top-center",
                                autoClose: 1000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "colored",
                            });
                        })
                        .catch((err) => {
                            console.log(err);
                            toast.error("Something went wrong please try again later", {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "colored",
                            });
                        });
                    break;
                case "feedExpense":
                    axios.delete(defaultApi + "/api/feedExpense/delete/" + obj).then((res) => {
                        console.log(res.data);
                        setAllExpense((prevData) =>
                            prevData.filter((expense) => expense._id !== obj)
                        );
                        toast.success("Feed expense deleted successfully", {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                    }).catch((err) => {
                        console.log(err);
                        toast.error("Something went wrong please try again later", {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                    });
                    break;

                default:
                    toast.error("Something went wrong please try again later", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    break;
            }
        }
    };

    const handleChange = (pagination, filters, sorter) => {
        console.log("Various parameters", pagination, filters, sorter);
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const renderPoultryName = (record) => {
        const poultryFarmId = record;
        const farmName = poultryFarm.find(
            (elem) => poultryFarmId === elem._id
        )?.name;
        return farmName || poultryFarmId;
    };

    const renderDate = (date) => {
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, "0");
        const month = String(d.getMonth() + 1).padStart(2, "0");
        const year = d.getFullYear();
        return `${day}/${month}/${year}`;
    };

    let navigate = useNavigate();

    const handleMenuClick = (key, record) => {
        setSelectedRow(record);
        console.log("record", record);

        if (key === "1") {
            navigate("/add-expense");
        } else if (key === "2") {
            // Handle update action
            console.log("Update clicked for:", record);
            setUpdateHandle(record);
            navigate("/add-expense#update");
        } else if (key === "3") {
            // Handle delete action
            deleteHandler(record);
        }
    };

    const items = JSON.parse(sessionStorage.getItem('UserData')).accountType === "5156" ? [
        {
            key: "1",
            label: "Add New",
            onClick: () => handleMenuClick("1", selectedRow),
            icon: <MdOutlineLibraryAdd />,
        },
        {
            key: "2",
            label: "Update",
            onClick: () => handleMenuClick("2", selectedRow),
            icon: <GrUpdate />,
        },
        {
            key: "3",
            danger: true,
            label: "Delete",
            icon: <RiDeleteBin6Line />,
            onClick: () => handleMenuClick("3", selectedRow),
        },
    ] : [
        {
            key: "1",
            label: "Add New",
            onClick: () => handleMenuClick("1", selectedRow),
            icon: <MdOutlineLibraryAdd />,
        },
    ]

    const columns = [
        {
            title: "Poultry Farm",
            dataIndex: "poultryFarm_FK",
            key: "poultryFarm_FK",
            ellipsis: false,
            render: renderPoultryName,
        },
        {
            title: "Date",
            dataIndex: "createdAt",
            key: "createdAt",
            ellipsis: false,
            render: renderDate,
        },
        {
            title: "Purpose of Expense",
            dataIndex: "expenseType",
            key: "expenseType",
            ellipsis: false,
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (data, record) => {
                // alert(data);

                return (
                    <strong>{toCaps(data)}</strong>
                );
            },
            ellipsis: false,
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
            render: (data, record) => {
                // alert(data);
                return (
                    <strong>{toComma(data)}</strong>

                );
            },
            ellipsis: false,
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
            render: (data, record) => {
                // alert(data);
                return (
                    <strong>{toComma(data)}</strong>

                );
            },
        },
        {
            title: "Added By",
            dataIndex: "user_FK",
            key: "user_FK",
            ellipsis: false,
            render: (data, record) => {
                // alert(data);
                return userData(data, allUsersObj) + " " + convertDateToPakistaniFormat(record.updatedAt);

            },
        },


        {
            title: "Options",
            key: "options",
            render: (_, record) => (
                <Dropdown
                    menu={{
                        items: items.map((item) => ({
                            ...item,
                            onClick: () => handleMenuClick(item.key, record),
                        })),
                    }}
                    placement="bottom"
                    arrow={{
                        pointAtCenter: true,
                    }}
                >
                    <p className="w-50 text-center">
                        <IoSettingsOutline size={"20px"} />
                    </p>
                </Dropdown>
            ),
        },
    ];

    return (
        <div>
            <CustomCard extra={"border-0 py-2 px-4 mb-5"}>

                <div className="d-flex justify-content-between align-items-center w-100">

                    <h4 className="h4 fw-bold my-3">Your Expense</h4>

                    <ConfigProvider theme={customTheme}>
                        <Button
                            type="primary"
                            className="float-end px-5 "
                            onClick={() => {
                                navigate("/add-expense");
                            }}
                        >
                            Add New Expense +
                        </Button>
                    </ConfigProvider>
                </div>
                {/* <DynamicTable TableHead={TableHead} TData={ExpenseData} /> */}
                <DynamicAntTable
                    column={columns}
                    TData={allexpenses.slice().reverse()}
                    handleChange={handleChange}
                />
            </CustomCard>
        </div>
    );
};

export default ExpenseList;
