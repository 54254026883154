import React, { useEffect, useState } from "react";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import {
  PoultryFarmState,
  ShedsState,
  UpdateHandle,
  allFlocks,
  defaultApiUrl,
  reRunApiFlag,
} from "../../Atom";
import { useRecoilState, useRecoilValue } from "recoil";

const FlocksForm = (props) => {
  let defaultApi = useRecoilValue(defaultApiUrl);
  let PoultryFarms = useRecoilValue(PoultryFarmState);
  let shedsState = useRecoilValue(ShedsState);
  let updateHandle = useRecoilValue(UpdateHandle);

  const [ShedsData, setShedsData] = useRecoilState(ShedsState); // eslint-disable-line no-unused-vars
  const [allFlocksData, setAllFlocks] = useRecoilState(allFlocks); // eslint-disable-line no-unused-vars
  let [reRunFlag, setReRunFlag] = useRecoilState(reRunApiFlag); // eslint-disable-line no-unused-vars

  let [updateFlag, setUpdateFlag] = useState(true);

  let initialValues = {
    poultryFarm_FK: "",
    shed_FK: "",
    flockType: "",
    purchasedQuantity: "",
    availableQuantity: "",
    price: "",
    mortalityRate: "",
    statusType: "",
  };

  let validationSchema = Yup.object({
    poultryFarm_FK: Yup.string().required("Required"),
    // shed_FK: Yup.string().required("Required"),
    shed_FK: Yup.string().when("poultryFarm_FK", {
      is: (poultryFarm_FK) => !poultryFarm_FK || poultryFarm_FK.length === 0,
      then: Yup.string().required("Select Poultry Farm First"),
      otherwise: Yup.string().required("Shed is required"),
    }),
    flockType: Yup.string().required("Required"),
    purchasedQuantity: Yup.string().required("Required"),

    mortalityRate: Yup.string().when([], {
      is: () => !updateFlag,
      then: Yup.string().required("Required"),
      otherwise: Yup.string(),
    }),
    statusType: Yup.string().when([], {
      is: () => !updateFlag,
      then: Yup.string().required("Required"),
      otherwise: Yup.string(),
    }),
    availableQuantity: Yup.string().when([], {
      is: () => !updateFlag,
      then: Yup.string().required("Quantity is Required"),
      otherwise: Yup.string(),
    }),

    price: Yup.number(),
  });

  var navigate = useNavigate();

  const location = useLocation();

  // Access the hash fragment (if any):
  const hashFragment = location.hash;

  useEffect(() => {
    console.log(hashFragment, "Update object", updateHandle);
    if (hashFragment === "#update") {
      setUpdateFlag(false);
    }
  }, [hashFragment]); // eslint-disable-line react-hooks/exhaustive-deps

  function SubmitShed(values, { resetForm }) {
    // alert("submit func");
    const Flocks = {
      poultryFarm_FK: values.poultryFarm_FK,
      shed_FK: values.shed_FK,
      flockType: values.flockType,
      purchasedQuantity: values.purchasedQuantity,
      availableQuantity: values.purchasedQuantity,
      price: values.price,
      mortalityRate: 0,
      statusType: "inProcess",
      user_FK: JSON.parse(sessionStorage.getItem("UserData"))._id,
      // _id: values.tableType,
    };

    // alert("values.statusTyp");

    if (!updateFlag) {
      Flocks._id = updateHandle._id;

      Flocks.mortalityRate = parseFloat(values.mortalityRate + updateHandle.mortalityRate);
      Flocks.availableQuantity = parseFloat(
        values.availableQuantity - values.mortalityRate
      );
      Flocks.statusType = values.statusType;

      // alert("update");
      axios
        .patch(defaultApi + "/api/flock/Update", Flocks)
        .then((res) => {
          console.log("Flock Update", res.data);

          const updatedIncome = res.data;
          setAllFlocks((prevFlock) =>
            prevFlock.map((Flock) =>
              Flock._id === updatedIncome._id ? updatedIncome : Flock
            )
          );

          if (Flocks.statusType === "sold") {
            // alert("sold ma aya")
            setShedsData((prevSheds) =>
              prevSheds.map((shed) =>
                shed._id === res.data.shed_FK
                  ? { ...shed, status: "vacant" }
                  : shed
              )
            );
          } else if (Flocks.statusType === "ready") {
            // alert("ready ma aya")
            setReRunFlag(true);
          }

          toast.success("Flock Has Been Updated", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          navigate("/Flocks-list");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .post(defaultApi + "/api/flock/Add", Flocks)
        .then((res) => {
          console.log("Flocks", res.data.addFlock);

          setAllFlocks((prevFlockss) => [res.data.addFlock, ...prevFlockss]);

          // setShedsData(prevShed => prevShed.map(shed =>
          //     shed._id === res.data.shed_FK ?  shed.status = 'Occupied' : shed
          // ))

          setShedsData((prevSheds) =>
            prevSheds.map((shed) =>
              shed._id === res.data.shed_FK
                ? { ...shed, status: "occupied" }
                : shed
            )
          );

          toast.success("Flock Has Been Added", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          navigate("/Flocks-list");
          // const sortedData = res.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          // setAllFlocks();
        })
        .catch((err) => {
          // console.log(err);
          // console.log("Flocks", err);
          toast.error("Error Adding Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }

    resetForm();
  }

  return (
    <Formik
      initialValues={updateFlag === true ? updateHandle : initialValues}
      validationSchema={validationSchema}
      // enableReinitialize
      onSubmit={SubmitShed}
      innerRef={props.formRef} // Attach the formRef to Formik's innerRef
    >
      {({ values, resetForm, setFieldValue }) => {
        return (
          <Form>
            <div className="row mt-4">
              <div className="col-md-12 text-center">
                <h3
                  onClick={() => {
                    console.log(initialValues, values);
                  }}
                >
                  <b>ADD NEW FLOCKS</b>
                </h3>
              </div>
            </div>
            <div className="row mt-3 justify-content-center">
              <div className="col-md-10 mb-3">
                <label htmlFor="poultryFarm_FK">Poultry Farm</label>
                <Field
                  as="select"
                  className="form-select"
                  name="poultryFarm_FK"
                  id="poultryFarm_FK"
                  disabled={!updateFlag}
                  value={values.poultryFarm_FK}
                >
                  <option value="">Select</option>
                  {PoultryFarms.map((elem, index) => {
                    let PreSelectFarm = JSON.parse(
                      sessionStorage.getItem("PreSelectFarm")
                    );
                    // alert(PreSelectFarm);
                    if (PreSelectFarm === elem._id) {
                      // setFieldValue("poultryFarm_FK", elem._id);
                      return (
                        <option key={index} selected value={elem._id}>
                          {" "}
                          {elem.name}{" "}
                        </option>
                      );
                    } else {
                      return (
                        <option
                          key={index}
                          selected={PoultryFarms.length === 1 && true}
                          value={elem._id}
                        >
                          {" "}
                          {elem.name}{" "}
                        </option>
                      );
                    }
                  })}
                </Field>
                <ErrorMessage
                  name="poultryFarm_FK"
                  className="text-danger"
                  component={"div"}
                />
              </div>

              <div className="col-md-10 mb-3">
                <label for="shed_FK">Sheds Farm</label>
                <Field
                  as="select"
                  className="form-select"
                  id="shed_FK"
                  name="shed_FK"
                  disabled={!updateFlag}
                >
                  <option value="" label="Select" />
                  {shedsState.map((option, index) => {
                    if (
                      values.poultryFarm_FK === option.poultryFarm_FK &&
                      option.status === "vacant"
                    ) {
                      return (
                        <option key={index} value={option._id}>
                          {option.name}
                        </option>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Field>
                <ErrorMessage
                  name="shed_FK"
                  className="text-danger"
                  component={"div"}
                />
              </div>

              <div className="col-md-10 mb-3">
                <label for="flockType">Flock Type</label>
                <Field
                  as="select"
                  disabled={!updateFlag}
                  className="form-select"
                  id="flockType"
                  name="flockType"
                >
                  <option value="" label="Select" />
                  <option value="hens" label="Hens" />
                  <option value="eggs" label="Eggs" />
                </Field>
                <ErrorMessage
                  name="flockType"
                  className="text-danger"
                  component={"div"}
                />
              </div>

              <div className="col-md-10 mb-3">
                <label for="purchasedQuantity">
                  {" "}
                  {!updateFlag && "Purchased"} Quantity
                </label>
                <Field
                  disabled={!updateFlag}
                  autoComplete="off"
                  id="purchasedQuantity"
                  className="form-control py-1"
                  type="number"
                  name="purchasedQuantity"
                />
                <ErrorMessage
                  name="purchasedQuantity"
                  className="text-danger"
                  component={"div"}
                />
              </div>

              {!updateFlag && (
                <>
                  <div className="col-md-10 mb-3">
                    <label for="availableQuantity">Available Quantity</label>
                    <Field
                      disabled={!updateFlag}
                      autoComplete="off"
                      id="availableQuantity"
                      className="form-control py-1"
                      type="number"
                      name="availableQuantity"
                    />
                    <ErrorMessage
                      name="availableQuantity"
                      className="text-danger"
                      component={"div"}
                    />
                  </div>
                  <div className="col-md-10 mb-3">
                    <label for="mortalityRate">Mortality Rate</label>
                    <Field
                      autoComplete="off"
                      id="mortalityRate"
                      className="form-control py-1"
                      type="number"
                      name="mortalityRate"
                    />
                    <ErrorMessage
                      name="mortalityRate"
                      className="text-danger"
                      component={"div"}
                    />
                  </div>
                  <div className="col-md-10 mb-3">
                    <label for="statusType">Status</label>
                    <Field
                      as="select"
                      className="form-select"
                      id="statusType"
                      name="statusType"
                    >
                      <option value="" label="Select" />
                      <option value="ready" label="Ready" />
                    </Field>
                    <ErrorMessage
                      name="statusType"
                      className="text-danger"
                      component={"div"}
                    />
                  </div>
                </>
              )}

              <div className="col-md-10 mb-3">
                <label for="price">Price</label>
                <Field
                  disabled={!updateFlag}
                  autoComplete="off"
                  id="price"
                  className="form-control py-1"
                  type="number"
                  name="price"
                />
                <ErrorMessage
                  name="price"
                  className="text-danger"
                  component={"div"}
                />
              </div>
            </div>

            <div className={`row mb-3 ${props.open && "d-none"} `}>
              <div className="col-md-12 text-center">
                <button type="submit" id="SubmitButton">
                  Submit
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FlocksForm;
