import React from 'react'
import CustomCard from '../assets/CustomCard'
import EmployeeForm from '../Forms/EmployeeForm'
import AddNew from '../AddNew/AddNew'
const Employee = (props) => {

    return (
        <div>
            <CustomCard extra={'border-0 py-2 px-4 mb-5'}>
                <div className="d-flex justify-content-between align-items-center w-100">

                    <h4 className='h4 fw-bold my-3'>Your Employee:</h4>
                    <AddNew btnText={'Add New Employee'} type="primary" >
                        <EmployeeForm />
                    </AddNew>
                </div>
                {props.comp}
            </CustomCard>
        </div>
    )
}

export default Employee
