import React from 'react';
import './PostShed.css';

import { useNavigate } from 'react-router-dom';


import 'react-toastify/dist/ReactToastify.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import AddShedFrom from './Forms/AddShedForm';


function PostShed() {

    let Navigate = useNavigate();
    function FrontPage() {
        Navigate('/Dashboard');
    }


    return (
        <>
            <div className='container'>
                <div className='row  justify-content-center'>

                    <div className='col-md-8'>

                        <div className='bg-white shadow my-5 py-4'>
                           
                            <AddShedFrom />
                        </div>


                    </div>
                    <div className='col-md-2 d-none'>
                        {/* Submit Button */}
                        <div className='row mb-3'>
                            <div className='col-md-12 text-center'>
                                <button id="SubmitButton" onClick={FrontPage}><FontAwesomeIcon icon={faHome} /> Home</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PostShed