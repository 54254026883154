// utils.js
let toComma = (x) => {
  const numStr = x.toString().replace(/\D/g, "");

  // Split the number into two parts: the last three digits and the rest
  const lastThreeDigits = numStr.slice(-3);
  const otherDigits = numStr.slice(0, -3);

  // Add commas after every two digits in the 'otherDigits' part
  const formattedOtherDigits = otherDigits.replace(
    /\B(?=(\d{2})+(?!\d))/g,
    ","
  );

  return otherDigits
    ? formattedOtherDigits + "," + lastThreeDigits
    : lastThreeDigits;
};
let toCaps = (string) => {
    // console.log(string);
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export { toComma, toCaps };