import { useRecoilValue } from "recoil";
import { allUsers } from "../../Atom";

function toSentenceCase(text) {
    if (!text) return ""; // Handle empty string case
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}


function toPascalCase(text) {
    if (!text) return ""; // Handle empty string case

    // Split text by spaces
    const words = text.toLowerCase().split(' ');

    // Capitalize first letter of each word
    const pascalWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

    // Join words together
    return pascalWords.join(' ');
}

function stripExtraSpaces(str) { return str.replace(/\s+/g, ' ').trim(); }



// let allUsersObj = useRecoilValue(allUsers)
function userData(id, userArray) {

    // console.log(id, userArray);

    let name = "";
    userArray.map((elem, index) => {
        if (elem._id === id) {
            name = elem.userName
        }
    })

    return toPascalCase(name) || "name lost Please Check Records";

}

// let allUsersObj = useRecoilValue(allUsers)
// {userData(elem.user_FK, allUsersObj)}


function convertDateToPakistaniFormat(dateString) {
    if (!dateString) {
        return ""; // Handle empty string case
    }

    try {
        // Parse the date string using a forgiving approach
        const dateObj = new Date(dateString);

        // Ensure valid date object
        if (isNaN(dateObj.getTime())) {
            // console.warn("Invalid date string:", dateString);
            return "";
        }

        // Options for Pakistani date formatting
        const options = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
        };

        // Format the date in Pakistani style with Pakistan Standard Time (PST)
        const formattedDate = dateObj.toLocaleString("en-PK", options);

        return formattedDate;
    } catch (error) {
        console.error("Error formatting date:", error);
        return ""; // Handle errors gracefully
    }
}


export { toSentenceCase, toPascalCase, stripExtraSpaces, userData, convertDateToPakistaniFormat };

