import { ErrorMessage, Field, Form, Formik } from 'formik'

import * as Yup from 'yup';

import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { toast } from 'react-toastify';
import { EmployeeData, PoultryFarmState, UpdateHandle, defaultApiUrl } from '../../Atom';
import { useRecoilState, useRecoilValue } from 'recoil';

const EmployeeForm = (props) => {
  let defaultApi = useRecoilValue(defaultApiUrl);
  let PoultryFarms = useRecoilValue(PoultryFarmState);
  let updateHandle = useRecoilValue(UpdateHandle);

  let [employee, setEmployee] = useRecoilState(EmployeeData);

  let [updateFlag, setUpdateFlag] = useState(true);

  var navigate = useNavigate();

  const location = useLocation();

  // Access the route path (without query parameters or hash):
  const currentRoute = location.pathname; // eslint-disable-line no-unused-vars

  // Access the query parameters (if any):
  const queryString = location.search; // eslint-disable-line no-unused-vars

  // Access the hash fragment (if any):
  const hashFragment = location.hash;


  useEffect(() => {
    // console.log(hashFragment, "Update object", updateHandle);
    if (hashFragment === "#update") {
      setUpdateFlag(false);

    }
  }, [hashFragment])// eslint-disable-line react-hooks/exhaustive-deps


  let initialValues = {
    poultryFarm_FK: JSON.parse(sessionStorage.getItem('PreSelectFarm')) || '',
    name: "",
    phoneNumber: "",
    CNIC: "",
    address: "",
    salary: "",
  }

  let validationSchema = Yup.object({

    name: Yup.string().required("Name Is Required"),
    phoneNumber: Yup.string()
      .trim()
      .matches(/^03\d{9}$/, 'Enter  11 digit phone Number eg: 03XXXXXXXXX') // Validate phone number format (adjust as needed)
      .required('Phone number is required'),
    CNIC: Yup.string()
      .trim()
      .matches(/^\d{13}$/, 'Enter 13 digit CNIC number without dashes "-"') // Validate phone number format (adjust as needed)
      .required('CNIC number is required'),
    address: Yup.string()
      .required('Required'),
    salary: Yup.string().required('Required').test(
      'Is positive?',
      'Salary must be positive',
      (value) => value > -1
    ),
    poultryFarm_FK: Yup.string().required("Please! select Poultry Farm"),
    // incomeDate: Yup.date().required()

  })

  let onSubmit = async (values, { resetForm }) => {

    let myObj = {
      poultryFarm_FK: values.poultryFarm_FK,
      name: values.name,
      phoneNumber: values.phoneNumber,
      CNIC: values.CNIC,
      address: values.address,
      salary: values.salary,
      user_FK: JSON.parse(sessionStorage.getItem("UserData"))._id
    }
    console.log(myObj)
    if (updateFlag) {
      axios
        .post(defaultApi + "/api/employee/add", myObj)
        .then((res) => {
          console.log(res.data);

          setEmployee([res.data, ...employee]);

          toast.success("Employee added successfully", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          if (!props.open) {
            setTimeout(function () {
              // resetForm();
              navigate("/Employee-list")
            }, 2000);
          }

          if (props.open) {
            props.setOpen(false);
          }
          resetForm();
        }
        ).catch((err) => {
          console.log(err);
        });
    }
    else {

      myObj._id = updateHandle._id;

      console.log(myObj);

      axios.patch(defaultApi + "/api/employee/update", myObj)
        .then((res) => {
          console.log(res.data);


          const updatedIncome = res.data;
          setEmployee(prevIncomes => prevIncomes.map(income =>
            income._id === updatedIncome._id ? updatedIncome : income
          ));

          toast.success("Employee updated successfully", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });


          setTimeout(function () {
            // resetForm();
            navigate("/Employee-list")
          }, 2000);

          if (props.open) {
            props.setOpen(false);
          }
          resetForm();
        }
        ).catch((err) => {
          console.log(err);
        });
    }
  }

  return (
    <Formik
      initialValues={hashFragment === "#update" ? updateHandle : initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      innerRef={props.formRef}
      enableReinitialize
    >
      {({ values, setFieldValue }) => {

        return (
          <Form>

            <div className='row mt-4'>
              <div className='col-md-12 text-center'>
                <h3><b>ADD NEW EMPLOYEE</b></h3>
              </div>
            </div>

            {/* added by */}
            <div className='row mt-3 justify-content-center'>

              {/* old Poultry Farm Setting
              <div className="col-md-10 mt-2">
                <label for="poultryFarm_FK">Poultry Farm</label>
                <Field
                  as="select"
                  className="form-select"
                  id="poultryFarm_FK"
                  name="poultryFarm_FK"
                  disabled={updateFlag === false ? true : false}
                >
                  <option value="" label="Select" />

                  {PoultryFarms.map((elem, index) => {
                    let PreSelectFarm = JSON.parse(sessionStorage.getItem('PreSelectFarm'));
                    if (PreSelectFarm === elem._id) {
                      return (<option key={index} selected value={elem._id} label={elem.name} />)
                    }
                    else { return (<option key={index} selected={PoultryFarms.length === 1 && true} value={elem._id} label={elem.name} />) }
                  })}

                </Field>
                <ErrorMessage name="poultryFarm_FK" className='text-danger' component={"div"} />
              </div>
               */}
              <div className="col-md-10 mt-2">
                <label htmlFor="poultryFarm_FK">Poultry Farm</label>
                <Field
                  as="select"
                  className="form-select"
                  name="poultryFarm_FK"
                  id="poultryFarm_FK"
                  disabled={!updateFlag}
                  value={values.poultryFarm_FK}
                >
                  <option value="">Select</option>
                  {PoultryFarms.map((option, index) => (
                    <option key={index} value={option._id}>
                        {option.name}
                    </option>
                ))}
                </Field>
                <ErrorMessage
                  name="poultryFarm_FK"
                  className="text-danger"
                  component={"div"}
                />
              </div>

              <div className="col-md-10 mt-2">
                <label for="name">Name</label>
                <Field autoComplete='off' id="name" className="form-control py-1" type="text" name="name" />
                <ErrorMessage name="name" className='text-danger' component={"div"} />
              </div>

              <div className="col-md-10 mt-2">
                <label for="phoneNumber">Phone Number</label>
                <Field autoComplete='off' id="phoneNumber" className="form-control py-1" type="text" name="phoneNumber" />
                <ErrorMessage name="phoneNumber" className='text-danger' component={"div"} />
              </div>

              <div className="col-md-10 mt-2">
                <label for="CNIC">CNIC</label>
                <Field autoComplete='off' id="CNIC" className="form-control py-1" type="text" name="CNIC" />
                <ErrorMessage name="CNIC" className='text-danger' component={"div"} />
              </div>

              <div className="col-md-10 mt-2">
                <label for="salary">Salary</label>
                <Field autoComplete='off' id="salary" className="form-control py-1" type="number" name="salary" />
                <ErrorMessage name="salary" className='text-danger' component={"div"} />
              </div>

              <div className="col-md-10 mt-2">
                <label for="address">Address</label>
                <Field rows="1" cols="3" autoComplete='off' className='form-control' type="string" id='address' placeholder="address" name="address" as="textarea" style={{ height: "37px" }} />
                <ErrorMessage name="address" className='text-danger' component={"div"} />
              </div>

            </div>


            <div className={`row my-4 ${props.open && 'd-none'} `}>
              <div className='col-md-12 text-center'>
                <button type='submit' id="SubmitButton">Submit</button>
              </div>
            </div>
          </Form>)
      }}

    </Formik>
  )
}

export default EmployeeForm
