import { atom } from "recoil";


export const defaultApiUrl = atom({
  key: "defaultApiUrl",
  // default: 'http://172.168.40.41:7001',
  // default: 'http://localhost:7000',
  // default: 'http://192.168.30.187:7000',
  // default: 'http://172.168.40.104:7000',
  // default: 'http://192.168.30.24:7001',
  // default: 'http://172.168.40.41:7001',
  default: 'https://poultryfarmbackend.usamafaheemahmed.com',
});

export const PoultryFarmState = atom({
  key: "PoultryFarmState",
  default: [],
});

export const incomes = atom({
  key: "incomes",
  default: [],
});

export const Inventory = atom({
  key: "Inventory",
  default: [],
});

export const EmployeeData = atom({
  key: "EmployeeData",
  default: [],
});

export const ShedsState = atom({
  key: "ShedsState",
  default: [
    {
      "poultryFarm_FK": "1010",
      "name": "Add New Shed",
      "area": "Kahna",
      "status": false
    }
  ],
});

export const LoggedInUserData = atom({
  key: "LoggedInUserData",
  default: {},
});

export const antDCustomTheme = atom({
  key: "antDCustomTheme",
  default: {
    components: {
      Modal: {
        titleColor: '#488a99',
        titleFontSize: 20
      },
    },
    token: {
      colorPrimary: '#488a99', // Use the primary color from the theme or default to #488a99
    },

    // Add more theme customizations if needed
  },
});

export const modalOpen = atom({
  key: "modalOpen",
  default: false,
});

export const UpdateHandle = atom({
  key: "UpdateHandle",
  default: {},
});

export const allFlocks = atom({
  key: 'allFlocks', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});

//Komail's work
export const allExpenses = atom({
  key: 'allExpenses', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});


export const UpdateExpenseHandle = atom({
  key: "UpdateExpenseHandle",
  default: {},
});


export const reRunApiFlag = atom({
  key: "reRunApiFlag",
  default: false,
});

export const allUsers = atom({
  key: "allUsers",
  default: [],
});



