import React, { useEffect, useState } from 'react'
import Widget from './Widget'

//mono 
import farm3 from '../assets/img/barn-mono-bg.svg'
import money3 from '../assets/img/money-mono-bg.svg'
import income3 from '../assets/img/profit-bar-mono-bg.svg'
import employee3 from '../assets/img/farmer-mono-bg.svg'

import Income from './Income'
import Employee from './Employee'
import PoultryFarm from './PoultryFarm'
import { useRecoilValue } from 'recoil'
import { PoultryFarmState, ShedsState, EmployeeData, incomes, allExpenses } from '../../Atom'
import { EmployeeTable } from '../ListView/EmployeeList'
import { IncomeListTable } from '../income/IncomeList'
import ExpenseList from '../expenses/Expenselist'


const Dashboard = () => {

    let [widgets, setWidgets] = useState([
        {
            Name: 'Poultry Farm',
            value: 1,
            symbol: '#',
            symbolLocation: 'prefix',
            icon: farm3,
            version: 1

        },
        {
            Name: 'Expense',
            value: 11000,
            symbol: 'Rs',
            symbolLocation: 'prefix',
            icon: money3,
            version: 1

        },
        {
            Name: 'Income',
            value: 11500,
            symbol: 'Rs',
            symbolLocation: 'prefix',
            icon: income3,
            version: 1

        },
        {
            Name: 'Employee',
            value: 23,
            symbol: '#',
            symbolLocation: 'prefix',
            icon: employee3,
            version: 1
        }
    ])

    let [option, setOption] = useState("Poultry Farm");

    const poultryFarm = useRecoilValue(PoultryFarmState);
    const ShedsData = useRecoilValue(ShedsState);
    const employeeData = useRecoilValue(EmployeeData);
    const incomesData = useRecoilValue(incomes);
    const allexpenses = useRecoilValue(allExpenses);



    useEffect(() => {
        if (poultryFarm.length) {
            setWidgets((prevWidgets) =>
                prevWidgets.map((widget) =>
                    widget.Name === 'Poultry Farm' ? { ...widget, value: poultryFarm.length } : widget
                )
            );

            let totalIncome2 = allexpenses.reduce((acc, item) => acc + item.price, 0);

            setWidgets((prevWidgets) =>
                prevWidgets.map((widget) =>
                    widget.Name === 'Expense' ? { ...widget, value: totalIncome2 } : widget
                )
            );

            setWidgets((prevWidgets) =>
                prevWidgets.map((widget) =>
                    widget.Name === 'Employee' ? { ...widget, value: employeeData.length } : widget
                )
            );


            let totalIncome = incomesData.reduce((acc, item) => acc + item.price, 0);


            setWidgets((prevWidgets) =>
                prevWidgets.map((widget) =>
                    widget.Name === 'Income' ? { ...widget, value: totalIncome } : widget
                )
            );
        }

    }, [poultryFarm, ShedsData, allexpenses, incomesData, employeeData]); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div className='col-md-12'>
            <Widget allWidget={widgets} setOption={setOption} />

            <div className='row mt-5'>
                <div className='col-md-12'>
                    {option === "Poultry Farm" &&
                        <PoultryFarm PoultryFarmData={poultryFarm} Sheds={ShedsData} />}
                    {option === "Expense" &&
                        <ExpenseList dashboard={true} />}
                    {option === "Income" &&
                        <Income comp={<IncomeListTable />} />}
                    {option === "Employee" &&
                        <Employee comp={<EmployeeTable />} />
                    }
                </div>
            </div>



        </div>
    )
}

export default Dashboard
