import React, { useState } from "react";
import CustomCard from "../assets/CustomCard";
import DynamicAntTable from "../assets/DynamicAntTable";
import { Button, ConfigProvider, Dropdown, Tag } from "antd";
import { IoSettingsOutline } from "react-icons/io5";
import {
  PoultryFarmState,
  ShedsState,
  UpdateHandle,
  allExpenses,
  allFlocks,
  allUsers,
  antDCustomTheme,
  defaultApiUrl,
} from "../../Atom";
import { useRecoilState, useRecoilValue } from "recoil";
import { MdOutlineLibraryAdd } from "react-icons/md";
import { GrUpdate } from "react-icons/gr";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import AddNew from "../AddNew/AddNew";
import ExpenseForm from "../Forms/ExpenceForm";
import { convertDateToPakistaniFormat, userData } from "../assets/GlobleFun";
import { toCaps, toComma } from "../../GlobalFunctions";

const FlocksList = (props) => {
  const [filteredInfo, setFilteredInfo] = useState({});
  const [selectedRow, setSelectedRow] = useState(null);
  const [sortedInfo, setSortedInfo] = useState({});

  let allUsersObj = useRecoilValue(allUsers);
  const poultryFarm = useRecoilValue(PoultryFarmState);
  let shedsState = useRecoilValue(ShedsState);
  const [updateHandle, setUpdateHandle] = useRecoilState(UpdateHandle);
  let allFlock = useRecoilValue(allFlocks);
  let defaultApi = useRecoilValue(defaultApiUrl);
  const [Flock, setAllFlocks] = useRecoilState(allFlocks);
  let navigate = useNavigate();

  let deleteHandler = (elem) => {
    if (window.confirm("Are you sure to want to delete this flock")) {
      var obj = elem._id;
      axios
        .delete(defaultApi + "/api/flock/Delete/" + obj)
        .then((res) => {
          setAllFlocks((prevData) =>
            prevData.filter((expense) => expense._id !== obj)
          );

          toast.error("Flock deleted successfully", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((err) => {
          toast.error("Something went wrong please try again later", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const renderPoultryName = (record) => {
    let poultryFarmId = record;
    poultryFarmId = poultryFarm.map((elem) => {
      if (poultryFarmId === elem._id) {
        return elem.name;
      }
    });
    return poultryFarmId;
  };

  const renderDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const renderShedName = (record) => {
    let inventoryId = record;
    inventoryId = shedsState.map((elem) => {
      if (inventoryId === elem._id) {
        return elem.name;
      }
    });
    return inventoryId;
  };

  const renderInventoryTypeTag = (type) => {
    switch (type) {
      case "inProcess":
        return <Tag color="cyan">In Process</Tag>;
      case "ready":
        return <Tag color="green">Flock is Ready</Tag>;
      case "sold":
        return <Tag color="red">Flock is Sold</Tag>;
      default:
        return <Tag color="gray">Unknown Type</Tag>;
    }
  };

  const handleMenuClick = (key, record) => {
    setSelectedRow(record);
    if (key === "1") {
      navigate("/add-Flocks");
    } else if (key === "2") {
      setUpdateHandle(record);
      navigate("/add-Flocks#update");
    } else if (key === "3") {
      deleteHandler(record);
    }
  };

  const items =
    JSON.parse(sessionStorage.getItem("UserData")).accountType === "5156"
      ? [
          {
            key: "1",
            label: "Add New",
            onClick: () => handleMenuClick("1", selectedRow),
            icon: <MdOutlineLibraryAdd />,
          },
          {
            key: "2",
            label: "Update",
            onClick: () => handleMenuClick("2", selectedRow),
            icon: <GrUpdate />,
          },
          {
            key: "3",
            danger: true,
            label: "Delete",
            icon: <RiDeleteBin6Line />,
            onClick: () => handleMenuClick("3", selectedRow),
          },
        ]
      : [
          {
            key: "1",
            label: "Add New",
            onClick: () => handleMenuClick("1", selectedRow),
            icon: <MdOutlineLibraryAdd />,
          },
        ];
        
        const columns = [
    {
      title: "Poultry Farm",
      dataIndex: "poultryFarm_FK",
      key: "poultryFarm_FK",
      render: renderPoultryName,
    },
    {
      title: "Shed",
      dataIndex: "shed_FK",
      key: "shed_FK",
      ellipsis: false,
      render: renderShedName,
      width: "100px",
    },
    {
      title: "Name",
      dataIndex: "flockType",
      key: "flockType",
      render: (data) => <strong>{toCaps(data)}</strong>,
      ellipsis: false,
    },
    {
      title: "Purchased Quantity",
      dataIndex: "purchasedQuantity",
      key: "purchasedQuantity",
      width: "140px",
      render: (data) => <strong>{toComma(data)}</strong>,
    },
    {
      title: "Available Quantity",
      dataIndex: "availableQuantity",
      key: "availableQuantity",
      width: "140px",
      render: (data) => <strong>{toComma(data)}</strong>,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (data) => <strong>{toComma(data)}</strong>,
    },
    {
      title: "Mortality Rate",
      dataIndex: "mortalityRate",
      key: "mortalityRate",
    },
    {
      title: "Added By",
      dataIndex: "user_FK",
      key: "user_FK",
      ellipsis: false,
      width: "240px",
      render: (data, record) =>
        userData(data, allUsersObj) +
        " " +
        convertDateToPakistaniFormat(record.updatedAt),
    },
    {
      title: "Status",
      dataIndex: "statusType",
      key: "statusType",
      render: renderInventoryTypeTag,
    },
    {
      title: "Options",
      key: "options",
      ellipsis: false,
      render: (_, record) => (
        <Dropdown
          menu={{
            items: items.map((item) => ({
              ...item,
              onClick: () => handleMenuClick(item.key, record),
            })),
          }}
          placement="bottom"
          arrow={{ pointAtCenter: true }}
        >
          <p className="w-50 text-center">
            <IoSettingsOutline size={"20px"} />
          </p>
        </Dropdown>
      ),
    },
  ];

  let customTheme = useRecoilValue(antDCustomTheme);

  return (
    <div>
      <CustomCard extra={"border-0 py-2 px-4 mb-5"}>
        <div className="d-flex justify-content-between align-items-center w-100">
          <h4 className="h4 fw-bold my-3">Your Flocks</h4>
          <ConfigProvider theme={customTheme}>
            <Button
              type="primary"
              className="float-end px-5"
              onClick={() => navigate("/add-Flocks")}
            >
              Add New Flock +
            </Button>
          </ConfigProvider>
          {props.dashboard && (
            <AddNew btnText={"Add New Expanse"} type="primary">
              <ExpenseForm />
            </AddNew>
          )}
        </div>
        <DynamicAntTable
          column={columns}
          TData={allFlock}
          handleChange={handleChange}
          filteredInfo={filteredInfo}
          sortedInfo={sortedInfo}
          rowSelection={false}
          size={"middle"}
          scroll={{ x: 1500 }} // Add this to enable horizontal scrolling
        />
      </CustomCard>
    </div>
  );
};

export default FlocksList;
