import React from 'react'
import { Dropdown as AntDropDown } from 'antd';

const CustomDropDowns = ({ placement, items, ...rest }) => {
    return (
        <AntDropDown menu={{ items }} trigger={['click']} placement={placement} arrow>
            {rest.children}
        </AntDropDown>
    );
}

export default CustomDropDowns
