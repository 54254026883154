import React, { useState } from 'react';

import Backdrop from './Backdrop';
import SideBar from './SideBar';
import ToolBar from './ToolBar';

function ThemeComp() {

  const [Sidebar, setSidebar] = useState(false);

  const ToggleSideBar = () => {
    setSidebar((prevState) => !prevState)
  }

  return (
    <>
      <ToolBar openSideBar={ToggleSideBar} />
      <Backdrop Sidebar={Sidebar} closeSidebar={ToggleSideBar} />
      <SideBar Sidebar={Sidebar} closeSidebar={ToggleSideBar} />

    </>
  )
}

export default ThemeComp