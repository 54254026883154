import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

const AddProduct = () => {
  var navigate = useNavigate();
  function FrontPage() {
    navigate('/Dashboard');
  }

  let letters = /^[a-zA-Z ]*$/;

  const [listOfCompanies, setListOfCompanies] = useState([])
  useEffect(() => {
    axios
      .get("http://localhost:7000/Company")
      .then((res) =>
        setListOfCompanies(res.data)
      );
  }, [])

  const formik = useFormik({
    initialValues: {
      productId: '',
      productName: '',
      companyName1: '',

    },

    onSubmit: (values, action) => {

      let myId = values.productId;
      let companyName = values.companyName1;
      let myproduct = values.productName;

      let myObj = {
        id: myId,
        productName: myproduct,
        companyName: companyName
      }


      axios
        .post("http://localhost:7000/Product/Add-Product", myObj)
        .then((res) => {
          toast.success("Product added successfully", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(function () {
            action.resetForm();
            navigate("/product-list")

          }, 2000);

        }
        );
    },
    validationSchema: Yup.object({
      productId: Yup.number().required('Required').test(
        'Is positive?',
        'id must be a positive number',
        (value) => value > 0
      ),
      productName: Yup.string().matches(letters, "name can only contain alphabet letters").min(3).max(20).required('Required'),

      companyName1: Yup.string().required('Required'),
    })


  })




  return (
    <>
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <ToastContainer />
            <form className='py-3 border rounded px-3 mt-3 ' onSubmit={formik.handleSubmit}>
              <div className='row mt-4'>
                <div className='col-md-12 text-center'>
                  <h3><b>ADD NEW PRODUCT</b></h3>
                </div>
              </div>
              {/* product Id  */}
              <div className='row mt-3'>
                <div className='col-md-1'></div>
                <div className='col-md-10'>
                  <label htmlFor='productId' >Id#</label>
                  <input type="number" className="form-control py-1" id="productId"
                    name="productId" onChange={formik.handleChange}
                    value={formik.values.productId} onBlur={formik.handleBlur} />
                  {formik.touched.productId && formik.errors.productId ? <div className='text-danger'>{formik.errors.productId}</div> : null}
                </div>
                <div className='col-md-1'></div>
              </div>

              {/* product Id */}
              <div className='row mt-3'>
                <div className='col-md-1'></div>
                <div className='col-md-10'>
                  <label htmlFor='productName' >Product Name</label>
                  <input autoComplete='off' className='form-control py-1' type="text" id='productName'
                    placeholder="product name" name='productName' onChange={formik.handleChange}
                    value={formik.values.productName} onBlur={formik.handleBlur} />
                  {formik.touched.productName && formik.errors.productName ? <div className='text-danger'>{formik.errors.productName}</div> : null}
                </div>
                <div className='col-md-1'></div>
              </div>

              <div className='row mt-3'>
                <div className='col-md-1'></div>
                <div className='col-md-10'>
                  <label htmlFor='companyName' >Company Name</label>
                  <select id='companyName' className="form-select" name="companyName1" onChange={formik.handleChange} value={formik.values.companyName1}>
                    <option value={""}>Select</option>

                    {listOfCompanies.map((elem, index) => {
                      let { companyName } = elem;
                      return (
                        <option key={index} value={companyName}>{companyName}</option>
                      );
                    })}
                  </select>
                  {formik.touched.companyName1 && formik.errors.companyName1 ? <div className='text-danger'>{formik.errors.companyName1}</div> : null}

                </div>
                <div className='col-md-1'></div>
              </div>


              <div className='row mb-4 mt-5'>
                <div className='col-md-12 text-center'>
                  <button type='submit' id='SubmitButton'>Submit</button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-2">
            <div className='row my-3'>
              <div className='col-md-12 text-center'>
                <button id="SubmitButton" onClick={FrontPage}><FontAwesomeIcon icon={faHome} /> Home</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddProduct