import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const ProductList = () => {

  let UserName = sessionStorage.getItem("UserName");
  let Navigate = useNavigate();
  function BacktoFrontPage() {
    Navigate('/Dashboard');
  }
  const [listOfProducts, setlistOfProducts] = useState([])
  const [listOfCompanies, setListOfCompanies] = useState([])


  const [pSId, setpSId] = useState("");
  const [pSCompany, setpSCompany] = useState("");
  const [pSName, setpSName] = useState("");

  //  let pId=1;
  //  let pCompany="dell";
  //  let pName="keyboard";

  let updateHandler = (elem) => {


    let PId = document.getElementById("productId").value = elem.id;
    let PCompany = document.getElementById("companyName1").value = elem.companyName;
    let PName = document.getElementById("productName").value = elem.productName;
    setpSId(PId);
    setpSCompany(PCompany);
    setpSName(PName);
    document.getElementById("formContainer").classList.remove("d-none")
  }

  let deleteHandler = (elem) => {
    if (window.confirm("Are you sure to want to delete " + elem.productName)) {
      var obj = elem.id;

      axios
        .delete(`http://localhost:7000/Product/Delete-Product/${obj}`)
        .then((res) => {
          setlistOfProducts(res.data)
        }
        );

      toast.success("product deleted successfully", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      window.location.reload();

    }


  }




  useEffect(() => {
    axios
      .get("http://localhost:7000/Company")
      .then((res) =>
        setListOfCompanies(res.data)

      );
    axios
      .get("http://localhost:7000/Product")
      .then((res) =>
        setlistOfProducts(res.data)
      );
  }, [])

  let handleSubmit = (e) => {
    e.preventDefault();
    let myObj = {
      id: pSId,
      productName: pSName,
      companyName: pSCompany
    }

    let productId = myObj.id;

    axios
      .patch(`http://localhost:7000/Product/Update-Product/${productId}`, myObj)
      .then((res) => {


        toast.success("updated successfully", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        window.location.reload()

      }
      );


  }



  return (
    <>
      <div className="container-fluid">
        <div className='row mb-3 text-center' id="MyShedsHeading">
          <div className='col-md-12'>
            <h2><b>MY PRODUCTS</b></h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8 ">
            <ToastContainer />
            {/* form */}

            <div id='formContainer' className='d-none container'>
              <form className='py-3 border rounded px-3 mt-3 ' onSubmit={handleSubmit}>

                <div className='row mt-4'>
                  <div className='col-md-12 text-center'>
                    <h3><b>UPDATE PRODUCT</b></h3>
                  </div>
                </div>

                {/* product Id  */}
                <div className='row mt-3'>
                  <div className='col-md-1'></div>
                  <div className='col-md-10'>
                    <label htmlFor='productId' >Id#</label>
                    <input readOnly type="number" className="form-control py-1" id="productId"
                      name="productId" value={pSId} onChange={(e) => setpSId(e.target.value)} />
                  </div>
                  <div className='col-md-1'></div>
                </div>

                <div className='row mt-3'>
                  <div className='col-md-1'></div>
                  <div className='col-md-10'>
                    <label htmlFor='companyName' >Company Name</label>
                    <select className="form-select" id='companyName1' name="companyName1"
                      value={pSCompany} onChange={(e) => setpSCompany(e.target.value)}>
                      <option value={""}>Select</option>

                      {listOfCompanies.map((elem, index) => {
                        let { companyName } = elem;
                        return (
                          <option key={index} value={companyName}>{companyName}</option>
                        );
                      })}
                    </select>
                  </div>
                  <div className='col-md-1'></div>
                </div>


                {/* product Id */}
                <div className='row mt-3'>
                  <div className='col-md-1'></div>
                  <div className='col-md-10'>
                    <label htmlFor='productName' >Product Name</label>
                    <input autoComplete='off' className='form-control py-1' type="text" id='productName' placeholder="product name"
                      name='productName' value={pSName} onChange={(e) => setpSName(e.target.value)} />
                  </div>
                  <div className='col-md-1'></div>
                </div>

                <div className='row my-4'>
                  <div className='col-md-12 text-center'>
                    <button type='submit' id="SubmitButton">Update</button>
                  </div>
                </div>
              </form>
            </div>
            {/* form */}
            <div className='row mt-5'>
              <div className='col-md-1'></div>
              <div className='col-md-10'>
                <table className="table table-bordered table-hover mt-3 text-center">
                  <thead>
                    <tr>
                      <th colSpan='4'>PRODUCT DETAILS</th>
                    </tr>
                    <tr>
                      <th>ID</th>
                      <th>Company Name</th>
                      <th>Product Name</th>
                      {
                        (UserName === "Admin") ?
                          <th>Operations</th> : <th className='d-none'></th>
                      }

                    </tr>
                  </thead>
                  <tbody>
                    {listOfProducts.map((elem, index) => {
                      let { id, productName, companyName } = elem;
                      return (

                        <tr key={id}>
                          <td>{id}</td>
                          <td>{companyName}</td>
                          <td>{productName}</td>
                          {
                            (UserName === "Admin") ?
                              <td >
                                <button onClick={() => updateHandler(elem)} type='btn' id="UpdateButton">Edit</button>
                                <button onClick={() => deleteHandler(elem)} type='btn' id="DeleteButton">Delete</button>
                              </td> : <td className='d-none'></td>
                          }
                        </tr>
                      );

                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
        <div className='row my-2'>
          <div className='col-md-12 text-center'>
            <button id="BackButton" onClick={BacktoFrontPage}><FontAwesomeIcon icon={faHome} /> Home</button>
          </div>
        </div>
      </div>

    </>
  )
}

export default ProductList