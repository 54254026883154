import React, { useEffect, useState } from "react";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Inventory,
  PoultryFarmState,
  UpdateHandle,
  allExpenses,
  allFlocks,
  defaultApiUrl,
} from "../../Atom";
import { useRecoilState, useRecoilValue } from "recoil";

const ExpenseForm = (props) => {
  let defaultApi = useRecoilValue(defaultApiUrl);
  let PoultryFarms = useRecoilValue(PoultryFarmState);
  let inventory = useRecoilValue(Inventory);
  let Flocks = useRecoilValue(allFlocks);
  let updateHandle = useRecoilValue(UpdateHandle);

  const [allexpenses, setAllExpense] = useRecoilState(allExpenses); // eslint-disable-line no-unused-vars

  const [InventoryData, setInventory] = useRecoilState(Inventory); // eslint-disable-line no-unused-vars

  let initialValues = {
    poultryFarm_FK: "",
    tableType: "",
    expenseType: "",
    name: "",
    quantity: "",
    price: "",

    // status: "",
  };

  let validationSchema = Yup.object({
    poultryFarm_FK: Yup.string().required("Required"),
    tableType: Yup.string().required("Required"),
    name: Yup.string().when("tableType", {
      is: (tableType) => tableType !== "flockExpense",
      then: Yup.string().required("Name is required"),
      otherwise: Yup.string().notRequired(),
    }),
    quantity: Yup.string().required("Quantity is Required"),
    price: Yup.number().when("tableType", {
      is: (tableType) => tableType !== "flockExpense",
      then: Yup.number().required("Price is required"),
      otherwise: Yup.number().notRequired(),
    }),
    expenseType: Yup.string().when("tableType", {
      is: (tableType) => tableType !== "flockExpense",
      then: Yup.string().required("Purpose is required"),
      otherwise: Yup.string().notRequired(),
    }),

    // status: Yup.string().required("Required"),
  });
  // let [employee, setEmployee] = useRecoilState(Employee);

  let [updateFlag, setUpdateFlag] = useState(true);

  var navigate = useNavigate();

  const location = useLocation();

  // Access the hash fragment (if any):
  const hashFragment = location.hash;

  useEffect(() => {
    console.log("Flocks", Flocks);
    // console.log(hashFragment, "Update object", updateHandle);
    if (hashFragment === "#update") {
      setUpdateFlag(false);
    }
  }, [hashFragment]); // eslint-disable-line react-hooks/exhaustive-deps

  function SubmitShed(values) {
    // alert("submit func");
    const Expense = {
      poultryFarm_FK: values.poultryFarm_FK,
      expenseType: values.expenseType,
      name: values.name,
      quantity: values.quantity,
      price: values.price,
      tableType: values.tableType,
      user_FK: JSON.parse(sessionStorage.getItem("UserData"))._id,

      // _id: values.tableType,
    };

    // console.log(values);
    switch (values.tableType) {
      case "dailyExpense":
        if (updateFlag === false) {
          Expense._id = updateHandle._id;
          // alert("update");
          axios
            .patch(defaultApi + "/api/dailyExpense/update", Expense)
            .then((res) => {
              console.log(res.data);
              setAllExpense((prevExpenses) => {
                // Find the index of the updated expense
                const updatedIndex = prevExpenses.findIndex(
                  (exp) => exp.id === res.data.id
                );
                // Create a new array with the updated expense
                const updatedExpenses = [
                  ...prevExpenses.slice(0, updatedIndex),
                  res.data,
                  ...prevExpenses.slice(updatedIndex + 1),
                ];

                return updatedExpenses;
              });
              toast.success("Daily Expense Has Been Updated", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              navigate("/expense-list");
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          axios
            .post(defaultApi + "/api/dailyExpense/Add", Expense)
            .then((res) => {
              console.log("dailyExpense", res);

              setAllExpense((prevExpenses) => [res.data, ...prevExpenses]);

              toast.success("Daily Expense Has Been Added", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              navigate("/expense-list");
              // const sortedData = res.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
              // setAllExpense();
            })
            .catch((err) => {
              // console.log(err);
              console.log("dailyExpense", err);
              toast.error("Error Adding Data", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            });
        }
        break;
      case "feedExpense":
        if (updateFlag === false) {
          // alert("updater");

          Expense._id = updateHandle._id;
          axios
            .patch(defaultApi + "/api/feedExpense/update", Expense)
            .then((res) => {
              // console.log(res.data);
              toast.success("Feed Expense Has Been Updated", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });

              setAllExpense((prevExpenses) => {
                // Find the index of the updated expense
                const updatedIndex = prevExpenses.findIndex(
                  (exp) => exp.id === res.data.id
                );
                // Create a new array with the updated expense
                const updatedExpenses = [
                  ...prevExpenses.slice(0, updatedIndex),
                  res.data,
                  ...prevExpenses.slice(updatedIndex + 1),
                ];

                return updatedExpenses;
              });
              getAllINventories();
              navigate("/expense-list");
            })
            .catch((err) => {
              console.log(err);
              toast.success("Error Updating Data", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            });
        } else {
          axios
            .post(defaultApi + "/api/feedExpense/addFeedInventory", Expense)
            .then((res) => {
              console.log("res of Add FeedInventory ", res);

              setAllExpense((prevExpenses) => [res.data, ...prevExpenses]);

              // create New object Forming From INventory and this res

              getAllINventories();

              console.log("feedExpense", res.data);

              toast.success("Feed Expense Has Been Added", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              navigate("/expense-list");
            })
            .catch((err) => {
              console.log("feedExpense", err);
              toast.error("Error Adding Data", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              // console.log(err);
            });
        }
        break;
      case "flockExpense":
        let flockObj = {
          inventory_FK: values.inventory_FK,
          flock_FK: values.flock_FK,
          quantity: Expense.quantity,
          user_FK: JSON.parse(sessionStorage.getItem("UserData"))._id,
        };

        console.log("flockObj", flockObj);
        axios
          .post(defaultApi + "/api/flockExpense/add", flockObj)
          .then((res) => {
            console.log("FlockExpense", res);
            toast.success("Flock Expense Has Been Added", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            getAllINventories();

            navigate("/expense-list");
          })
          .catch((err) => {
            console.log("FlockExpense", err);
            toast.error("Error Adding Data", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            // console.log(err);
          });
        // code block
        break;
      default:
        toast.error("Something went wrong please! try again Later!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        break;

      // code block
    }
  }

  function convertDateToPakistaniFormat(dateString) {
    if (!dateString) {
      return ""; // Handle empty string case
    }

    try {
      // Parse the date string using a forgiving approach
      const dateObj = new Date(dateString);

      // Ensure valid date object
      if (isNaN(dateObj.getTime())) {
        // console.warn("Invalid date string:", dateString);
        return "";
      }

      // Options for Pakistani date formatting
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      };

      // Format the date in Pakistani style with Pakistan Standard Time (PST)
      const formattedDate = dateObj.toLocaleString("en-PK", options);

      return formattedDate;
    } catch (error) {
      console.error("Error formatting date:", error);
      return ""; // Handle errors gracefully
    }
  }

  // let [inventory, setInventory] = useRecoilState(Inventory);// eslint-disable-line no-unused-vars

  let getAllINventories = async () => {
    //Inventory Get ALL
    let inventoryData = await axios.get(defaultApi + "/api/inventory/");
    if (inventoryData) {
      console.log(inventoryData.data, "inventoryData naya wala ");
      let res = inventoryData.data.slice().reverse();
      setInventory(res);
    }
  };

  return (
    <Formik
      initialValues={updateFlag === true ? updateHandle : initialValues}
      validationSchema={validationSchema}
      onSubmit={SubmitShed}
      innerRef={props.formRef} // Attach the formRef to Formik's innerRef
    >
      {({ values }) => {
        return (
          <Form>
            <div className="row mt-4">
              <div className="col-md-12 text-center">
                <h3>
                  <b>ADD NEW EXPENSE</b>
                </h3>
              </div>
            </div>
            <div className="row mt-3 justify-content-center">
              <div className="col-md-10 mb-3">
                <label htmlFor="tableType">Expense Type</label>
                <Field
                  as="select"
                  className="form-select"
                  name="tableType"
                  id="tableType"
                  disabled={!updateFlag}
                >
                  <option value="">Select</option>
                  <option value="dailyExpense">Daily Expense</option>
                  <option value="feedExpense">Feed Expense</option>
                  <option value="flockExpense">Flock Expense</option>
                </Field>
                <ErrorMessage
                  name="tableType"
                  className="text-danger"
                  component={"div"}
                />
              </div>
              <div className="col-md-10 mb-3">
                <label htmlFor="poultryFarm_FK">Poultry Farm</label>
                <Field
                  as="select"
                  className="form-select"
                  name="poultryFarm_FK"
                  id="poultryFarm_FK"
                  disabled={!updateFlag}
                >
                  <option value="">Select</option>
                  {PoultryFarms.map((elem, index) => {
                    let PreSelectFarm = JSON.parse(
                      sessionStorage.getItem("PreSelectFarm")
                    );
                    // alert(PreSelectFarm);
                    if (PreSelectFarm === elem._id) {
                      // setFieldValue("poultryFarm_FK", elem._id);
                      return (
                        <option key={index} selected value={elem._id}>
                          {" "}
                          {elem.name}{" "}
                        </option>
                      );
                    } else {
                      return (
                        <option
                          key={index}
                          selected={PoultryFarms.length === 1 && true}
                          value={elem._id}
                        >
                          {" "}
                          {elem.name}{" "}
                        </option>
                      );
                    }
                  })}
                </Field>
                <ErrorMessage
                  name="poultryFarm_FK"
                  className="text-danger"
                  component={"div"}
                />
              </div>
              <div className="row mt-4">
                <div className="col-md-12 text-center">
                  <h4>
                    <b>
                      {values.tableType === "flockExpense"
                        ? "Flock Expense"
                        : values.tableType === "feedExpense"
                          ? "Feed Expense"
                          : "Daily Expense"}
                    </b>
                  </h4>
                </div>
              </div>
              {values.tableType === "flockExpense" || (
                <div className="col-md-10 mb-3">
                  <label htmlFor="name">Name</label>
                  {values.tableType === "feedExpense" ? (
                    <Field
                      as="select"
                      className="form-select"
                      name="name"
                      id="name"
                    >
                      <option value="">Select</option>
                      <option value="feed">Feed</option>
                      <option value="medicine">Medicine</option>
                    </Field>
                  ) : (
                    <Field
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Name"
                      id="name"
                    />
                  )}
                  <ErrorMessage
                    name="name"
                    className="text-danger"
                    component={"div"}
                  />
                </div>
              )}
              {values.tableType === "flockExpense" || (
                <div className="col-md-10 mb-3">
                  <label htmlFor="price">Price</label>
                  <Field
                    type="number"
                    className="form-control"
                    name="price"
                    id="price"
                    placeholder="Price"
                  />
                  <ErrorMessage
                    name="price"
                    className="text-danger"
                    component={"div"}
                  />
                </div>
              )}

              {values.tableType === "flockExpense" && (
                <div className="col-md-10 mt-2">
                  <label for="inventory_FK">Inventory Type</label>

                  <Field
                    autoComplete="off"
                    id="inventory_FK"
                    className="form-select py-1"
                    type="text"
                    name="inventory_FK"
                    disabled={updateFlag === false ? true : false}
                    as="select"
                  >
                    <option value="">Select</option>
                    {/* <option value="Feed">Feed</option>
                    <option value="Medicine">Medicine</option> */}
                    {/***/}
                    {inventory.map((elem, index) => {
                      if (elem.inventoryType === "purchased" && elem.quantity !== 0) {
                        // console.log(values.poultryFarm_FK);
                        if (values.poultryFarm_FK === elem.poultryFarm_FK) {
                          let pakistaniDate = convertDateToPakistaniFormat(
                            elem.createdAt
                          );
                          if (elem.quantity > 0) {
                            return (
                              <option value={elem._id}>
                                {elem.name}
                                <strong>
                                  {" quantity(" + elem.quantity + ") "}
                                </strong>
                                {pakistaniDate}
                              </option>
                            );
                          }
                        } else {
                          return null;
                        }
                      } else {
                        return null;
                      }
                    })}
                  </Field>
                  <ErrorMessage
                    name="inventory_FK"
                    className="text-danger"
                    component={"div"}
                  />
                </div>
              )}
              {values.tableType === "flockExpense" && (
                <div className="col-md-10 mt-2">
                  <label for="flock_FK">Select Flock</label>

                  <Field
                    autoComplete="off"
                    id="flock_FK"
                    className="form-select py-1"
                    type="text"
                    name="flock_FK"
                    disabled={updateFlag === false ? true : false}
                    as="select"
                  >
                    <option value="">Select</option>
                    {/***/}
                    {Flocks.map((elem, index) => {
                      let date = elem.date;
                      let pakistaniDate = convertDateToPakistaniFormat(date);
                      if (elem.statusType === "ready") {
                        if (elem.poultryFarm_FK === values.poultryFarm_FK) {
                          return (
                            <option value={elem._id}>
                              {elem.flockType} {pakistaniDate}
                            </option>
                          );
                        } else {
                          return null;
                        }
                      } else {
                        return null;
                      }
                    })}
                  </Field>
                  <ErrorMessage
                    name="flock_FK"
                    className="text-danger"
                    component={"div"}
                  />
                </div>
              )}

              <div className="col-md-10 mb-3 mt-2">
                <label htmlFor="quantity">Quantity</label>
                <Field
                  type="number"
                  className="form-control"
                  name="quantity"
                  id="quantity"
                  placeholder="Quantity"
                />
                <ErrorMessage
                  name="quantity"
                  className="text-danger"
                  component={"div"}
                />
              </div>

              {values.tableType === "flockExpense" || (
                <div className="col-md-10 mb-3">
                  <label htmlFor="expenseType">Purpose of Expense</label>
                  <Field
                    type="text"
                    className="form-control"
                    name="expenseType"
                    id="expenseType"
                    placeholder="Purpose"
                  />
                  <ErrorMessage
                    name="expenseType"
                    className="text-danger"
                    component={"div"}
                  />
                </div>
              )}
            </div>

            <div className={`row mb-3 ${props.open && "d-none"} `}>
              <div className="col-md-12 text-center">
                <button type="submit" id="SubmitButton">
                  Submit
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ExpenseForm;
