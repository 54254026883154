import { Button, ConfigProvider, Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { antDCustomTheme } from "../../Atom";
import { useRecoilValue } from "recoil";

const AddNew = (props) => {
  let customTheme = useRecoilValue(antDCustomTheme);
  const [open, setOpen] = useState(false);
  const [editObject, setEditObject] = useState({});

  const formRef = useRef(null); // Create a ref to store the form instance

  const handleOk = () => {
    if (formRef.current) {
      formRef.current.handleSubmit(); // Trigger Formik's handleSubmit
    }
    setEditObject({});
    if (props.edit) {
      props.editReset("");
    }
  };

  useEffect(() => {
    let { edit, id } = props;
    if (edit && id) {
      if (props.edit === props.id) {
        setOpen(true);
        setEditObject(props.ModalObj);
    }
}
else{
    // alert("WTF")
    setOpen(false);
    }
  }, [props.edit, props.id]);

  return (
    <div>
      <ConfigProvider theme={customTheme}>
        <Button
          type={props.type}
          block
          style={props.styles}
          onClick={() => setOpen(true)}
        >
          {props.btnText} +
        </Button>
        <Modal
          title={""}
          centered
          open={open}
          onOk={handleOk}
          onCancel={() => {
            setOpen(false);
            if (props.edit) {
              props.editReset("");
            }
          }}
          width={700}
          styles={{
            header: { color: "#488a99" }, // Custom class for header
          }}
          okText="Submit"
        >
          {props.children
            ? React.cloneElement(props.children, {
                formRef,
                open,
                setOpen,
                editObject,
              })
            : null}
        </Modal>
      </ConfigProvider>
    </div>
  );
};
export default AddNew;
// {props.children}
