import axios from 'axios';
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { EmployeeData, Inventory, LoggedInUserData, PoultryFarmState, ShedsState, allExpenses, allFlocks, allUsers, defaultApiUrl, incomes, reRunApiFlag } from './Atom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { toPascalCase, toSentenceCase } from './Components/assets/GlobleFun';


const Preload = () => {

    let [poultryFarm, setPoultryFarm] = useRecoilState(PoultryFarmState);
    const [ShedsData, setShedsData] = useRecoilState(ShedsState);
    let defaultApi = useRecoilValue(defaultApiUrl);
    let Login = useRecoilValue(LoggedInUserData);
    let [reRunFlag, setReRunFlag] = useRecoilState(reRunApiFlag);// eslint-disable-line no-unused-vars
    let [income, setIncomes] = useRecoilState(incomes);// eslint-disable-line no-unused-vars
    let [inventory, setInventory] = useRecoilState(Inventory);// eslint-disable-line no-unused-vars
    let [employee, setEmployee] = useRecoilState(EmployeeData);// eslint-disable-line no-unused-vars
    let [Expanses, setAllExpenses] = useRecoilState(allExpenses);// eslint-disable-line no-unused-vars
    let [Flocks, setAllFlocks] = useRecoilState(allFlocks);// eslint-disable-line no-unused-vars

    let [allUsersObj, setAllUsersObj] = useRecoilState(allUsers)


    let [loggedUser, setLoggedUser] = useRecoilState(LoggedInUserData)

    let navigate = useNavigate();



    const location = useLocation();

    // Access the route path (without query parameters or hash):
    const currentRoute = location.pathname;

    // Access the query parameters (if any):
    const queryString = location.search;

    // Access the hash fragment (if any):
    const hashFragment = location.hash;

    useEffect(() => {
        console.log("Current location", currentRoute);
        if (!sessionStorage.getItem("loggedIn")) {
            if (currentRoute === "/Register") {
                navigate('/Register');
            }
            else {
                navigate('/login');
            }
        }
    }, [currentRoute])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        if (sessionStorage.getItem("loggedIn")) {
            fetchData()
        }

    }, [Login])


    async function fetchData() {
        let id = JSON.parse(sessionStorage.getItem('UserData'));
        id = id._id;

        if (Login && Object.keys(Login).length > 0 && !Login._id) {
            setLoggedUser(id);
            // console.log("Login Data:", Login)
        }

        try {
            let allUsers = await axios.get(defaultApi + '/api/userRegistration/');
            if (allUsers.data) {
                console.log(allUsers.data)
                sessionStorage.setItem("allUsers", JSON.stringify(allUsers.data));
                setAllUsersObj(allUsers.data)
            }
            else {
                throw Error("Some Error came")
            }
        }
        catch (error) {
            console.error('Error fetching data:', error);
            // Handle error as needed
        }




        if (poultryFarm.length === 0) {
            try {
                // Fetch Poultry Farm data
                let poultryFarmData = await axios.get(defaultApi + '/api/poultryFarm/');
                poultryFarmData = poultryFarmData.data;

                const correctedPoultryFarmData = poultryFarmData.map((obj) => ({
                    ...obj,
                    Name: toPascalCase(obj.Name),
                    Location: toSentenceCase(obj.Location),
                    Manager: toSentenceCase(obj.Manager),
                }));

                setPoultryFarm(correctedPoultryFarmData);

                // Fetch Sheds data asynchronously
                const shedDataPromises = correctedPoultryFarmData.map(async (farm) => {
                    let shedsData = await axios.get(defaultApi + '/api/Shed/shedsByPF/' + farm._id);
                    shedsData = shedsData.data.map((obj) => ({
                        ...obj,
                        name: toSentenceCase(obj.name),
                        area: toSentenceCase(obj.area),
                    }));
                    return shedsData;
                });

                // console.log(shedDataPromises)
                // Wait for all shed data promises to resolve
                const shedDataResults = await Promise.all(shedDataPromises);

                // Flatten the array of shed data arrays
                const allShedsData = shedDataResults.reduce((acc, cur) => acc.concat(cur), []);

                allShedsData.unshift(ShedsData[0]);

                setShedsData(allShedsData);


                // Income Getting
                // Fetch income data asynchronously
                const incomePromises = correctedPoultryFarmData.map(async (farm) => {
                    let incomeData = await axios.get(defaultApi + '/api/income/incomeByPF/' + farm._id);
                    incomeData = incomeData.data;
                    return incomeData;
                });

                // Wait for all income data promises to resolve
                const incomeDataResults = await Promise.all(incomePromises);

                // Flatten the array of shed data arrays
                const allIncomeData = incomeDataResults.reduce((acc, cur) => acc.concat(cur), []);

                // console.log("allIncomeData", allIncomeData)

                setIncomes(allIncomeData);



                // Fetch expanse data asynchronously
                const expansePromises = correctedPoultryFarmData.map(async (farm) => {
                    let expanseData = await axios.get(defaultApi + '/api/combinedDailyFeedExpense/byPF/' + farm._id);
                    // let expanseData = await axios.get('http://192.168.100.27:7000/api/combinedDailyFeedExpense/byPF/' + farm._id);
                    expanseData = expanseData.data;
                    return expanseData;
                });

                // Wait for all expanse data promises to resolve
                const expanseDataResults = await Promise.all(expansePromises);

                // Flatten the array of shed data arrays
                const allExpanseData = expanseDataResults.reduce((acc, cur) => acc.concat(cur), []);

                // console.log("allExpanseData", allExpanseData)

                setAllExpenses(allExpanseData);


                // Fetch flock data asynchronously
                const flockPromises = correctedPoultryFarmData.map(async (farm) => {
                    let flockData = await axios.get(defaultApi + '/api/flock/flocksByPF/' + farm._id);
                    flockData = flockData.data;
                    return flockData;
                });

                // Wait for all flock data promises to resolve
                const flockDataResults = await Promise.all(flockPromises);

                // Flatten the array of shed data arrays
                const allFlocksData = flockDataResults.reduce((acc, cur) => acc.concat(cur), []);

                // console.log("flocksByPF", allFlocksData)

                setAllFlocks(allFlocksData);


                //Inventory Get ALL
                let inventoryData = await axios.get(defaultApi + '/api/inventory/');
                if (inventoryData) {
                    // console.log(inventoryData.data);z
                    setInventory(inventoryData.data.slice().reverse());
                }

                //employee Get ALL all conditions applied on inventory also apply on Employee
                let employeeData = await axios.get(defaultApi + '/api/employee/');
                if (employeeData) {
                    // console.log(employeeData.data);
                    setEmployee(employeeData.data.slice().reverse());
                }

            } catch (error) {
                console.error('Error fetching data:', error);
                // Handle error as needed
            }
        }
    }


    return (
        <div>
        </div>
    )
}

export default Preload
