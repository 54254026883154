import React from 'react';
import './SideBar.css';

import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore, faBuilding, faUser, faMoneyBill, faDollar, faCartShopping, faPlus, faHome, faCartFlatbed } from '@fortawesome/free-solid-svg-icons';
import { Accordion, Image } from 'react-bootstrap';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';

import chick from "./assets/SVG/chick-hatched-from-egg-svgrepo-com.svg"
import { LoggedInUserData } from '../Atom';
import { useRecoilValue } from 'recoil';


function SideBar({ Sidebar, closeSidebar }) {

  let UserName = sessionStorage.getItem("UserName");

  let Navigate = useNavigate();

  function Dashboard() {
    Navigate('/');
    closeSidebar();
  }
  function ShowAllSheds() {
    Navigate('/Show-Sheds');
    closeSidebar();
  }
  function AddingShed() {
    Navigate('/Add-Shed');
    closeSidebar();
  }

  function ShowAllCompanies() {
    Navigate('/company-list');
    closeSidebar();
  }
  function AddingCompany() {
    Navigate('/add-company');
    closeSidebar();
  }

  function ShowAllUsers() {
    Navigate('/user-list');
    closeSidebar();
  }
  function AddingUsers() {
    Navigate('/add-user');
    closeSidebar();
  }

  function ShowAllProducts() {
    Navigate('/product-list');
    closeSidebar();
  }
  function AddingProducts() {
    Navigate('/add-product');
    closeSidebar();
  }

  function ShowAllExpenses() {
    Navigate('/expense-list');
    closeSidebar();
  }
  function AddingExpenses() {
    Navigate('/add-expense');
    closeSidebar();
  }

  function ShowAllIncomes() {
    Navigate('/income-list');
    closeSidebar();
  }
  function AddingIncomes() {
    Navigate('/add-income');
    closeSidebar();
  }

  function ShowAllInventory() {
    Navigate('/inventory-list');
    closeSidebar();
  }
  function AddingInventory() {
    Navigate('/add-inventory');
    closeSidebar();
  }
  function ShowAllEmployee() {
    Navigate('/Employee-list');
    closeSidebar();
  }
  function AddingEmployee() {
    Navigate('/add-Employee');
    closeSidebar();
  }
  function ShowAllFlocks() {
    Navigate('/Flocks-list');
    closeSidebar();
  }
  function AddingFlocks() {
    Navigate('/add-Flocks');
    closeSidebar();
  }
  let Login = useRecoilValue(LoggedInUserData);

  return (
    <div className={`${Sidebar ? "sidebar sidebar--open" : "sidebar"} custom-scrollbar`}>
      <li className='logoText' onClick={closeSidebar}>
        <h5 className='text-center'>
          POULTRY <span>Farm!</span></h5>
      </li>
      <li className="d-none" onClick={Dashboard}><FontAwesomeIcon icon={faHome} className='me-2' /> Dashboard</li>

      <Accordion>
        <Accordion.Item className='rounded-0' eventKey="0">
          <Accordion.Header className='custom_accordion noIcon' onClick={Dashboard}><FontAwesomeIcon icon={faHome} className='me-2' /> Dashboard</Accordion.Header>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header><FontAwesomeIcon icon={faStore} className='me-2' /> Shed</Accordion.Header>
          <Accordion.Body className="p-0 m-0">
            <li style={{ all: "unset", height: "0px" }}></li>
            <li onClick={ShowAllSheds}><FontAwesomeIcon icon={faStore} className='me-2' /> Show All Sheds</li>
            <li onClick={AddingShed}><FontAwesomeIcon icon={faPlus} className='me-2' /> Add New Shed</li>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header><Image src={chick} alt='chicks' fluid style={{ width: "20px" }} className='me-2' /> Flock</Accordion.Header>
          <Accordion.Body className="p-0 m-0">
            <li style={{ all: "unset", height: "0px" }}></li>
            <li onClick={ShowAllFlocks}><Image src={chick} alt='chicks' fluid style={{ width: "20px" }} className='me-2' />  Show All Flocks</li>
            <li onClick={AddingFlocks}><FontAwesomeIcon icon={faPlus} className='me-2' /> Add New Flock</li>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header className="fw-bold"><FontAwesomeIcon icon={faUsers} className='me-2' />Employees</Accordion.Header>
          <Accordion.Body className="p-0 m-0">
            <li style={{ all: "unset", height: "0px" }}></li>
            <li onClick={ShowAllEmployee}><FontAwesomeIcon icon={faUsers} className='me-2' /> Show All Employees</li>
            <li onClick={AddingEmployee}><FontAwesomeIcon icon={faPlus} className='me-2' /> Add New Employees</li>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5">
          <Accordion.Header className="fw-bold"> <FontAwesomeIcon icon={faMoneyBill} className='me-2' />Expenses</Accordion.Header>
          <Accordion.Body className="p-0 m-0">
            <li style={{ all: "unset", height: "0px" }}></li>
            <li onClick={ShowAllExpenses}><FontAwesomeIcon icon={faMoneyBill} className='me-2' /> Show All Expenses</li>
            <li onClick={AddingExpenses}><FontAwesomeIcon icon={faPlus} className='me-2' /> Add New Expense</li>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="6">
          <Accordion.Header className="fw-bold"> <FontAwesomeIcon icon={faCartFlatbed} className='me-2' />  Inventory</Accordion.Header>
          <Accordion.Body className="p-0 m-0">
            <li style={{ all: "unset", height: "0px" }}></li>

            <li onClick={ShowAllInventory}><FontAwesomeIcon icon={faCartFlatbed} className="me-2" />Show All Inventory</li>
            <li onClick={AddingInventory}><FontAwesomeIcon icon={faPlus} className='me-2' /> Add New Inventory</li>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item className='rounded-0' eventKey="7">
          <Accordion.Header className='custom_accordion'> <FontAwesomeIcon icon={faDollar} className='me-2' />  Income</Accordion.Header>
          <Accordion.Body className="p-0 m-0">
            <li style={{ all: "unset", height: "0px" }}></li>

            <li onClick={ShowAllIncomes}><FontAwesomeIcon icon={faDollar} className='me-2' /> Show All Incomes</li>
            <li onClick={AddingIncomes}><FontAwesomeIcon icon={faPlus} className='me-2' /> Add New Income</li>
          </Accordion.Body>
        </Accordion.Item>


        {Login.accountType === "515622" &&
          <Accordion.Item className='rounded-0' eventKey="7">
            <Accordion.Header className='custom_accordion'> <FontAwesomeIcon icon={faDollar} className='me-2' />  Users</Accordion.Header>
            <Accordion.Body className="p-0 m-0">
              <li style={{ all: "unset", height: "0px" }}></li>

              <li onClick={ShowAllUsers}><FontAwesomeIcon icon={faUser} className='me-2' /> Show All Users</li>
              <li onClick={AddingUsers}><FontAwesomeIcon icon={faPlus} className='me-2' /> Add New User</li>
            </Accordion.Body>
          </Accordion.Item>
        }

      </Accordion>

    </div>
  )
}

export default SideBar