import React, { useEffect, useState } from 'react'
import CustomCard from '../assets/CustomCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEgg, faEnvelope, faLocationDot, faPhone, faUserGear } from '@fortawesome/free-solid-svg-icons'
import Shed4Picture from '../assets/img/Shed3Picture.jpg';
import farm from '../assets/SVG/farm-svgrepo-com.svg';

import { Accordion, Image } from 'react-bootstrap'
import { useRecoilState, useRecoilValue } from 'recoil';
import { LoggedInUserData, PoultryFarmState, ShedsState, UpdateHandle, allFlocks, allUsers, antDCustomTheme, defaultApiUrl } from '../../Atom';
import { convertDateToPakistaniFormat, toSentenceCase, userData } from '../assets/GlobleFun';
import { Button, ConfigProvider, Popconfirm, Tooltip } from 'antd';

import AddNew from '../AddNew/AddNew';
import AddShedFrom from '../Forms/AddShedForm';
import AddPoultryFarmForm from '../Forms/AddPoultryFarmForm';
import { FiEdit } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';

import { RiDeleteBin6Line, RiUserSettingsLine } from 'react-icons/ri';
import axios from 'axios';
import { toast } from 'react-toastify';
import { IoCheckmarkDoneCircleOutline } from 'react-icons/io5';

const Sheds = ({ ShedsData }) => {

    const [updateHandle, setUpdateHandle] = useRecoilState(UpdateHandle);
    let allUsersObj = useRecoilValue(allUsers)

    let allFlock = useRecoilValue(allFlocks);
    const [PopConfirm, setPopConfirm] = useState(false);

    let Nav = useNavigate();

    function shedEdit(Data) {
        deleteHandler(Data);
    }

    const [ShedS, setShedsState] = useRecoilState(ShedsState);

    let defaultApi = useRecoilValue(defaultApiUrl);

    let deleteHandler = (elem) => {
        var obj = elem._id;
        var tableType = elem.tableType;
        console.log(elem.tableType);

        axios
            .delete(defaultApi + "/api/shed/Delete/" + obj)
            .then((res) => {
                console.log(res);
                setShedsState((prevData) =>
                    prevData.filter((shed) => shed._id !== obj)
                );

                toast.error("Shed deleted successfully", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
            .catch((err) => {
                console.log(err);
                toast.error("Something went wrong please try again later", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            });
    };
    function FlockEdit(Flock) {
        setUpdateHandle(Flock);
        Nav('/add-Flocks#update');
    }


    // Shed Cards 
    return (
        <div>
            <div className='row align-items-stretch'>
                {ShedsData.map((Element) => {
                    if (Element.name !== "Add New Shed") {
                        const flock = allFlock.find(flock => flock.shed_FK === Element._id) || {};
                        return (
                            <div className='col-md-6'>
                                <CustomCard extra={'mb-3 Card-hover'} radius="15px" >
                                    <div className="row g-0">
                                        <div className="col-md-4">
                                            <Image src={Shed4Picture} className=' rounded-start-15 w-100 h-100' fluid />
                                        </div>
                                        <div className="col-md-8 px-3 position-relative">
                                            <h6 className='mt-3'><b>{Element.name}</b>
                                                <Popconfirm
                                                    title="Delete the Shed"
                                                    description={
                                                        <React.Fragment>
                                                            Are you sure to delete this shed?
                                                            {flock?._id && (
                                                                <React.Fragment>
                                                                    <br />
                                                                    ( <b>Remember:  </b> you need to assign new shed to this flock)
                                                                </React.Fragment>
                                                            )}
                                                        </React.Fragment>
                                                    }

                                                    onConfirm={() => { shedEdit(Element) }}
                                                    onCancel={() => setPopConfirm(false)}
                                                    okText="Yes"
                                                    cancelText="No"
                                                    placement="top"
                                                >
                                                    <Tooltip placement="left" title={"Shed Delete"}>
                                                        <b className='float-end text-danger curserPointer'> <RiDeleteBin6Line /></b>
                                                    </Tooltip>
                                                </Popconfirm>
                                            </h6>
                                            <div className='d-flex flex-wrap justify-content-start align-items-center gap-3 mb-2'>
                                                <div>
                                                    <FontAwesomeIcon icon={faEgg} /> {Element.status === "occupied" ? "Occupied" : "Vacant"}
                                                </div>

                                                {Element.status && flock?._id &&
                                                    <div className='ms-auto'>
                                                        <Tooltip placement="left" title={"Flock Edit"}>
                                                            <b className='float-end C-hoverColor curserPointer' onClick={() => { FlockEdit(flock) }}> <FiEdit /></b>
                                                        </Tooltip>
                                                    </div>
                                                }
                                            </div>

                                            {Element.status && flock?._id &&
                                                <div className='d-flex flex-wrap justify-content-start align-items-center gap-3'>
                                                    <div>
                                                        <p className='fw-bold h6'>Flock: <span className='fw-normal'>{flock.flockType}</span></p>
                                                    </div>
                                                    <div>
                                                        <p className='fw-bold h6'>Quantity: <span className='fw-normal'> {flock.availableQuantity}</span></p>
                                                    </div>
                                                </div>
                                            }
                                            <div className='d-flex flex-wrap justify-content-start align-items-center gap-3 d-none'>
                                                <div>
                                                    <p className='fw-bold h6'>Expense: <span className='fw-normal'>890</span></p>
                                                </div>
                                                <div>
                                                    <p className='fw-bold h6'>Income: <span className='fw-normal'>520</span></p>
                                                </div>
                                            </div>
                                            <div className='d-flex flex-wrap justify-content-start align-items-center gap-3 '>
                                                <div>
                                                    <p className='fw-bold h6'>Created By: <span className='fw-normal'>{userData(Element.user_FK, allUsersObj) + " " + convertDateToPakistaniFormat(Element.updatedAt)}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CustomCard>
                            </div>
                        )
                    }
                    else {
                        return (
                            <div className='col-md-6'>
                                <AddNew id="shedForm" btnText={Element.name} type="dashed" styles={{ minHeight: "9rem", heigh: "100%" }}>
                                    <AddShedFrom />
                                </AddNew>
                            </div>
                        )
                    }
                })
                }
            </div>
        </div>
    )
}


const PoultryFarm = () => {

    let ShedsData = useRecoilValue(ShedsState);
    let PoultryFarmArray = useRecoilValue(PoultryFarmState);
    let allUsersObj = useRecoilValue(allUsers)


    let customTheme = useRecoilValue(antDCustomTheme)

    let [EditState, setEditState] = useState("");
    let [isSelected, setIsSelected] = useState();
    let [ModalObj, setModalObj] = useState({});

    let editPoultryFarm = (data) => {
        setEditState("poultryFarm")
        setModalObj(data);
    }

    let futureSelect = (id) => {
        if (isSelected == id) {
            sessionStorage.removeItem("PreSelectFarm");
            setIsSelected("")
        }
        else {
            setIsSelected(id)
            sessionStorage.setItem("PreSelectFarm", JSON.stringify(id));
        }
    }

    useEffect(() => {
        console.log(ShedsData);
        let PreSelectFarm = sessionStorage.getItem('PreSelectFarm');

        if (PreSelectFarm) {
            setIsSelected(JSON.parse(PreSelectFarm))
        }
    }, [])

    let Login = useRecoilValue(LoggedInUserData);

    return (
        <div>
            <CustomCard extra={'border-0 py-2 px-4 mb-5'}>
                <div className="d-flex justify-content-between align-items-center w-100">
                    <h4 className='h4 fw-bold my-3 d-inline-block'>Your Poultry Farm:</h4>
                    <AddNew edit={EditState} editReset={setEditState} ModalObj={ModalObj} id="poultryFarm" btnText={'Add New Poultry Farm'} type="primary" >
                        <AddPoultryFarmForm />
                    </AddNew>
                </div>
                <div className='row mb-3'>
                    <Accordion defaultActiveKey="0">
                        {
                            PoultryFarmArray.map((elem, index) => {
                                let OS = toSentenceCase(elem.ownerShipStatus);
                                // Find all sheds that match the current poultry farm's _id
                                let matchingSheds = ShedsData.filter(shed => shed.poultryFarm_FK === elem._id);

                                matchingSheds.unshift(ShedsData[0]);
                                // console.log(matchingSheds)
                                return (
                                    <Accordion.Item eventKey={index}>
                                        <Accordion.Header>
                                            <div className="d-flex justify-content-between align-items-center pe-3 w-100">
                                                <strong>{elem.name}</strong>
                                                <div className="d-flex justify-content-center align-items-center gap-3">
                                                    <RiDeleteBin6Line className="text-danger d-none" />

                                                    <Tooltip title={isSelected == elem._id ? 'Selected for all forms. Unselect by clicking again' : 'Click to select for all forms'}>
                                                        <IoCheckmarkDoneCircleOutline
                                                            color={isSelected == elem._id ? 'green' : 'currentColor'} // Dynamic color based on selection
                                                            size={24} // Adjust size as needed
                                                            onClick={() => { futureSelect(elem._id) }}
                                                        />
                                                    </Tooltip>

                                                    {JSON.parse(sessionStorage.getItem('UserData')).accountType === "5156" &&
                                                        <FiEdit onClick={() => { editPoultryFarm(elem) }} />
                                                    }

                                                </div>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className='row'>
                                                <div className='col-md-6 mb-3'>
                                                    <h5>{elem.name}_{OS}_{elem.branch}</h5>
                                                    <p className='p-0 m-0 '><RiUserSettingsLine style={{ margin: "0px 8px 0px 0px" }} /> {userData(elem.user_FK, allUsersObj)}</p>
                                                    <p className='p-0 m-0'> <Image src={farm} fluid className='w-5 pe-2 ps-0' style={{ margin: "0px 0px 0px 0px", maxWidth: "30px" }} />  <span style={{ margin: "0px 0px 0px -5px", }}>  {OS} </span> </p>
                                                    <p className='p-0 m-0 d-none'><FontAwesomeIcon icon={faEnvelope} /> {elem.email}</p>
                                                    <p className='p-0 m-0 '><FontAwesomeIcon icon={faPhone} className='ms-1 pe-1 ps-0 me-0' /> {elem.phoneNumber}</p>
                                                    <p className='p-0 m-0 '><FontAwesomeIcon icon={faLocationDot} className='ms-1 me-2' /> {elem.address}</p>
                                                </div>
                                                <div className='col-md-6 d-none'>
                                                    <ConfigProvider theme={customTheme}>
                                                        <Button type="dashed" className='float-end px-5 '>
                                                            Add New Shed +
                                                        </Button>
                                                    </ConfigProvider>
                                                </div>
                                            </div>
                                            <Sheds ShedsData={matchingSheds} />
                                        </Accordion.Body>
                                    </Accordion.Item>)
                            })
                        }
                    </Accordion>
                </div>
            </CustomCard>
        </div>
    )
}

export default PoultryFarm
