import React, { useState } from 'react'
import CustomCard from '../assets/CustomCard'
import { Card } from 'react-bootstrap'

import UserProfile from "../assets/img/usamaNew.jpg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons'
import NewUserFrom from './NewUserFrom'


// import UserProfile2 from "https://bootdey.com/img/Content/avatar/avatar7.png"

const ViewProfile = () => {

    const [SampleData, setSampleData] = useState({
        id: "",
        user: "",
        Name: "Usama Faheem Ahmed",
        email: "usamafaheem80@gmail.com",
        password: "admin",
        phoneNumber: "03124513576",
        address: "Pak Arab Lahore",
        accountType: "Admin",
        imageUrl: "",  //fill waqt Emplty hai phir hum aga kara ga 
    }
    );



    return (
        <div className='container-fluid'>
            <div className='row my-3'>
                <div className='col-md-3'>
                    <CustomCard extra={'mb-3 Card-hover'} radius="15px">
                        <div className='d-flex justify-content-center my-3'>
                            {/**<Card.Img variant="top" src={`https://bootdey.com/img/Content/avatar/avatar7.png`} className='rounded-circle image-fluid w-50' /> */}
                            <div className='overflow-hidden rounded-circle' style={{ width: "10rem", height: "10rem" }}>
                                <Card.Img variant="top" src={SampleData.imageUrl ? URL.createObjectURL(SampleData.imageUrl) : UserProfile} className='image-fluid' />
                            </div>
                        </div>
                        <Card.Body>
                            <Card.Title className='text-center'>{SampleData.Name}</Card.Title>
                            <Card.Text className='text-left ps-2'>
                                <p className='p-0 m-0 text-truncate fs-14'> <FontAwesomeIcon icon={faPhone} /> {SampleData.phoneNumber}</p>
                                <p className='p-0 m-0 text-truncate fs-14'> <FontAwesomeIcon icon={faEnvelope} /> {SampleData.email}</p>
                                <p className='p-0 m-0 text-truncate fs-14'> <FontAwesomeIcon icon={faLocationDot} /> {SampleData.address}</p>
                            </Card.Text>
                        </Card.Body>
                    </CustomCard>
                </div>
                <div className='col-md-9'>
                    <CustomCard extra={'mb-3 px-3 py-2 C-color2'} radius="15px">
                        <div className='row'>
                            <h5 className='fw-bold my-3'>You Details:</h5>

                            <NewUserFrom SampleData={SampleData} setSampleData={setSampleData} />

                        </div>
                    </CustomCard>
                </div>
            </div>
        </div>
    )
}

export default ViewProfile
