import React, { useEffect, useState } from "react";

import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

import { toast } from "react-toastify";
import { PoultryFarmState, defaultApiUrl } from "../../Atom";
import { useRecoilState, useRecoilValue } from "recoil";

const AddPoultryFarmForm = (props) => {
  let defaultApi = useRecoilValue(defaultApiUrl);
  let [poultryFarm, setPoultryFarm] = useRecoilState(PoultryFarmState);

  let initialValues = {
    user_FK: JSON.parse(sessionStorage.getItem("UserData"))._id,
    ownerShipStatus: "",
    name: "",
    rentAmount: "",
    address: "",
    branch: "",
    phoneNumber: "",
    chicksCapacity: "",
    numberOfSheds: "",
  };

  let validationSchema = Yup.object({
    address: Yup.string().required(),

    chicksCapacity: Yup.string().required("Chicks capacity is Required"),
    numberOfSheds: Yup.string().required("Number of Sheds is Required"),
    branch: Yup.string().required("Branch Name is Required"),

    ownerShipStatus: Yup.string().required("Ownership status is required"),

    rentAmount: Yup.string()
      .when("ownerShipStatus", {
        is: "rented",
        then: Yup.string().required(
          "Rent amount is required for rented properties"
        ),
      })
      .notRequired(), // Optional: Default required message

    name: Yup.string()
      .trim() // Remove leading/trailing whitespace
      .required("Name  is required"),
    phoneNumber: Yup.string()
      .trim()
      .matches(/^03\d{9}$/, "Enter  11 digit phone Number eg: 03XXXXXXXXX") // Validate phone number format (adjust as needed)
      .required("Phone number is required"),
  });

  function SubmitFarm(values, { resetForm }) {
    // check this
    const Farm = {
      user_FK: values.user_FK,
      name: values.name,
      ownerShipStatus: values.ownerShipStatus,
      rentAmount:
        values.ownerShipStatus === "rented" ? values.rentAmount : undefined,
      address: values.address,
      branch: values.branch,
      phoneNumber: values.phoneNumber,
      user_FK: JSON.parse(sessionStorage.getItem("UserData"))._id,
    };

    console.log(JSON.stringify(Farm));

    if (!EditState) {
      /* issue needed to handle in Backend api is just filtering on base on branch not Corresponding branch of user which
              will create issues later */
      axios
        .post(defaultApi + "/api/poultryFarm/Add", Farm)
        .then((res) => {
          // console.log(res.data);
          if (props.open) {
            props.setOpen(false);
          }
          setPoultryFarm([...poultryFarm, res.data]);
          toast.success("User poultry farm is created", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          resetForm();
        })
        .catch((err) => {
          // console.log(err)
          // console.error(err.response);
          if (err.response.status === 400) {
            toast.error("Something went wrong please try again later ... ", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else if (err.response.status === 401) {
            toast.error(err.response.data, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        });
    } else {
      Farm._id = EditObj._id;

      axios
        .patch(defaultApi + "/api/poultryFarm/update", Farm)
        .then((res) => {
          if (props.open) {
            props.setOpen(false);
          }
          updatePoultryFarmList(res.data); // Custom function to update the list

          toast.success("Poultry farm updated successfully", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          resetForm();
        })
        .catch((err) => {
          if (err.response && err.response.status === 400) {
            toast.error("Something went wrong. Please try again later.", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else if (err.response && err.response.status === 401) {
            toast.error(err.response.data, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        });
    }
  }

  let [EditState, setEditFlag] = useState(false);
  let [EditObj, setEditObj] = useState({});

  // Function to update a poultry farm in the list
  const updatePoultryFarmList = (updatedFarm) => {
    // Map through the array to find the item with the same _id and update it
    const updatedList = poultryFarm.map((farm) => {
      if (farm._id === updatedFarm._id) {
        return updatedFarm; // Replace with the updated farm object
      }
      return farm; // Keep the farm object unchanged
    });

    setPoultryFarm(updatedList); // Update state with the new list
  };

  useEffect(() => {
    if (
      props.editObject &&
      Object.keys(props.editObject).length > 0 &&
      props.editObject?._id
    ) {
      // alert("FoundData")
      console.log(JSON.stringify(props.editObject));
      setEditFlag(true);
      setEditObj(props.editObject);
    }
  }, []);

  return (
    <Formik
      initialValues={EditState ? EditObj : initialValues}
      validationSchema={validationSchema}
      onSubmit={SubmitFarm}
      enableReinitialize
      innerRef={props.formRef} // Attach the formRef to Formik's innerRef
    >
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <div className="row mt-4">
              <div className="col-md-12 text-center">
                <h3>
                  <b>ADD NEW POULTRY FARM</b>
                </h3>
              </div>
            </div>
            <div className="row mb-4 justify-content-center">
              <div className="col-md-6 mt-2">
                <label htmlFor="name">Name</label>

                <Field
                  autoComplete="off"
                  className="form-control"
                  type="string"
                  id="name"
                  placeholder="Poultry Farm Name"
                  name="name"
                />
                <ErrorMessage
                  name="name"
                  className="text-danger"
                  component={"div"}
                />
              </div>

              <div className="col-md-6 mt-2">
                <label htmlFor="ownerShipStatus">Status</label>
                <Field
                  autoComplete="off"
                  className="form-select"
                  type="string"
                  id="ownerShipStatus"
                  name="ownerShipStatus"
                  as="select"
                >
                  <option value={""}>Select</option>
                  <option value={"Owner"}>Owner</option>
                  <option value={"rented"}>Rented</option>
                </Field>
                <ErrorMessage
                  name="ownerShipStatus"
                  className="text-danger"
                  component={"div"}
                />
              </div>

              {values.ownerShipStatus === "rented" && (
                <div className="col-md-6 mt-2">
                  <label htmlFor="rentAmount">Rent Amount</label>
                  <Field
                    autoComplete="off"
                    className="form-control"
                    type="string"
                    id="rentAmount"
                    placeholder="Enter Amount"
                    name="rentAmount"
                  />
                  <ErrorMessage
                    name="rentAmount"
                    className="text-danger"
                    component={"div"}
                  />
                </div>
              )}

              <div
                className={` ${
                  values.ownerShipStatus === "rented" ? "col-md-6" : "col-md-12"
                }   mt-2`}
              >
                <label htmlFor="branch">Branch Name</label>
                <Field
                  autoComplete="off"
                  className="form-control"
                  type="string"
                  id="branch"
                  placeholder="Enter numbers of Sheds"
                  disabled={EditState}
                  name="branch"
                />
                <ErrorMessage
                  name="branch"
                  className="text-danger"
                  component={"div"}
                />
              </div>

              <div className="col-md-6 mt-2">
                <label htmlFor="chicksCapacity">Chicken Capacity</label>
                <Field
                  autoComplete="off"
                  className="form-control"
                  type="number"
                  id="chicksCapacity"
                  placeholder="Chicken Capacity"
                  name="chicksCapacity"
                />
                <ErrorMessage
                  name="chicksCapacity"
                  className="text-danger"
                  component={"div"}
                />
              </div>

              <div className="col-md-6 mt-2">
                <label htmlFor="numberOfSheds">No of Sheds </label>
                <Field
                  autoComplete="off"
                  className="form-control"
                  type="number"
                  id="numberOfSheds"
                  placeholder="Enter numbers of Sheds"
                  name="numberOfSheds"
                />
                <ErrorMessage
                  name="numberOfSheds"
                  className="text-danger"
                  component={"div"}
                />
              </div>

              <div className="col-md-6 mt-2">
                <label htmlFor="phoneNumber">Phone</label>
                <Field
                  autoComplete="off"
                  className="form-control"
                  type="string"
                  id="phoneNumber"
                  placeholder="03XXXXXXXXX"
                  name="phoneNumber"
                />
                <ErrorMessage
                  name="phoneNumber"
                  className="text-danger"
                  component={"div"}
                />
              </div>

              <div className="col-md-6 mt-2">
                <label htmlFor="address">Address</label>
                <Field
                  rows="1"
                  cols="3"
                  autoComplete="off"
                  className="form-control"
                  type="string"
                  id="address"
                  placeholder="address"
                  name="address"
                  as="textarea"
                  style={{ height: "37px" }}
                />
                <ErrorMessage
                  name="address"
                  className="text-danger"
                  component={"div"}
                />
              </div>
            </div>

            <div className={`row mb-3 ${props.open && "d-none"} `}>
              <div className="col-md-12 text-center">
                <button id="SubmitButton" type="submit">
                  Submit
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddPoultryFarmForm;
