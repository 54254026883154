import { ErrorMessage, Field, Form, Formik } from 'formik'

import * as Yup from 'yup';

import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { toast } from 'react-toastify';
import { Inventory, PoultryFarmState, UpdateHandle, allFlocks, defaultApiUrl, incomes } from '../../Atom';
import { useRecoilState, useRecoilValue } from 'recoil';

const IncomeForm = (props) => {
    let defaultApi = useRecoilValue(defaultApiUrl);
    let PoultryFarms = useRecoilValue(PoultryFarmState);
    let inventory = useRecoilValue(Inventory);
    let updateHandle = useRecoilValue(UpdateHandle);
    let allFlock = useRecoilValue(allFlocks);


    let [income, setIncomes] = useRecoilState(incomes);

    let [updateFlag, setUpdateFlag] = useState(true);
    let [autoSet, setAutoSet] = useState(false);

    var navigate = useNavigate();

    const location = useLocation();

    // Access the route path (without query parameters or hash):
    const currentRoute = location.pathname;

    // Access the query parameters (if any):
    const queryString = location.search;

    // Access the hash fragment (if any):
    const hashFragment = location.hash;


    useEffect(() => {
        console.log(hashFragment, "Update object", updateHandle);
        if (hashFragment === "#update") {
            setUpdateFlag(false);

        }
    }, [hashFragment])// eslint-disable-line react-hooks/exhaustive-deps


    let initialValues = {
        poultryFarm_FK: JSON.parse(sessionStorage.getItem('PreSelectFarm')) || '',
        inventory_FK: "",
        quantity: "",
        price: "",
        // incomeDate: "",
    }

    let validationSchema = Yup.object({

        quantity: autoSet ? Yup.number().notRequired() : Yup.number()
            .required('Required')
            .min(0, 'Quantity must be greater than or equal to 0')
            .test(
                'is-valid-quantity',
                'Quantity exceeds available inventory',
                function (value) {
                    const { inventory_FK } = this.parent;
                    const selectedInventory = inventory.find(item => item._id === inventory_FK);
                    return selectedInventory ? value <= selectedInventory.quantity : true;
                }
            ),
        price: Yup.string().required('Required').test(
            'Is positive?',
            'price must be positive',
            (value) => value > -1
        ),

        poultryFarm_FK: Yup.string().required("Please! select Poultry Farm"),
        inventory_FK: Yup.string().required("Please! select inventory"),

        // incomeDate: Yup.date().required()

    })



    function convertDateToPakistaniFormat(dateString) {
        if (!dateString) {
            return ""; // Handle empty string case
        }

        try {
            // Parse the date string using a forgiving approach
            const dateObj = new Date(dateString);

            // Ensure valid date object
            if (isNaN(dateObj.getTime())) {
                // console.warn("Invalid date string:", dateString);
                return "";
            }

            // Options for Pakistani date formatting
            const options = {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
            };

            // Format the date in Pakistani style with Pakistan Standard Time (PST)
            const formattedDate = dateObj.toLocaleString("en-PK", options);

            return formattedDate;
        } catch (error) {
            console.error("Error formatting date:", error);
            return ""; // Handle errors gracefully
        }
    }




    let onSubmit = async (values, action) => {

        let myObj = {
            poultryFarm_FK: values.poultryFarm_FK,
            inventory_FK: values.inventory_FK,
            quantity: values.quantity,
            price: values.price,
            user_FK: JSON.parse(sessionStorage.getItem("UserData"))._id
            // incomeDate: new Date(),
        }
        console.log(myObj)
        if (updateFlag) {
            axios
                .post(defaultApi + "/api/income/add", myObj)
                .then((res) => {
                    console.log(res.data.income);

                    setIncomes([res.data.income, ...income]);

                    toast.success("Income added successfully", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });

                    getAllINventories()



                    if (!props.open) {
                        setTimeout(function () {
                            action.resetForm();
                            navigate("/income-list")
                        }, 2000);
                    }

                    if (props.open) {
                        props.setOpen(false);
                    }
                }
                ).catch((err) => {
                    console.log(err);
                });
        }
        else {

            myObj._id = updateHandle._id;

            console.log(myObj);

            axios.patch(defaultApi + "/api/income/update", myObj)
                .then((res) => {
                    console.log(res.data);


                    const updatedIncome = res.data;
                    setIncomes(prevIncomes => prevIncomes.map(income =>
                        income._id === updatedIncome._id ? updatedIncome : income
                    ));

                    toast.success("Income updated successfully", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });

                    getAllINventories()

                    setTimeout(function () {
                        action.resetForm();
                        navigate("/income-list")
                    }, 2000);

                    if (props.open) {
                        props.setOpen(false);
                    }
                }
                ).catch((err) => {
                    console.log(err);
                });
        }
    }

    const [InventoryData11, setInventory] = useRecoilState(Inventory); // eslint-disable-line no-unused-vars


    let getAllINventories = async () => {
        //Inventory Get ALL
        let inventoryData = await axios.get(defaultApi + "/api/inventory/");
        if (inventoryData) {
            console.log(inventoryData.data, "inventoryData naya wala ");
            let res = inventoryData.data.slice().reverse();
            setInventory(res);
        }
    };



    return (
        <Formik
            initialValues={hashFragment === "#update" ? updateHandle : initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            innerRef={props.formRef}
            enableReinitialize
        >
            {({ values, handleChange, setFieldValue }) => {

                return (
                    <Form>

                        <div className='row mt-4'>
                            <div className='col-md-12 text-center'>
                                <h3><b>ADD NEW INCOME</b></h3>
                            </div>
                        </div>

                        {/* added by */}
                        <div className='row mt-3 justify-content-center'>

                            <div className="col-md-10 mt-2">
                                <label for="poultryFarm_FK">Poultry Farm</label>
                                <Field
                                    as="select"
                                    className="form-select"
                                    id="poultryFarm_FK"
                                    name="poultryFarm_FK"
                                    disabled={updateFlag === false ? true : false}
                                >
                                    <option value="" label="Select" />
                                    {PoultryFarms.map((option, index) => (
                                        <option key={index} value={option._id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </Field>
                                <ErrorMessage name="poultryFarm_FK" className='text-danger' component={"div"} />
                            </div>

                            <div className="col-md-10 mt-2">
                                <label for="inventory_FK">Inventory Farm</label>

                                <Field autoComplete='off' id="inventory_FK"
                                    className="form-select py-1"
                                    type="text" name="inventory_FK"
                                    disabled={updateFlag === false ? true : false}
                                    onChange={(e) => {
                                        handleChange(e.target.value);
                                        const selectedOption = inventory.find(option => option._id === e.target.value);
                                        setFieldValue('inventory_FK', e.target.value);

                                        if (selectedOption && selectedOption.inventoryType !== "shedIn") {
                                            // console.log(selectedOption);
                                            setAutoSet(true);
                                            // setFieldValue('quantity', selectedOption.flock_FK.availableQuantity);
                                            allFlock.map((flockObj, index) => {
                                                if (selectedOption.flock_FK == flockObj._id) {

                                                    setFieldValue('quantity', flockObj.availableQuantity);

                                                }
                                            })
                                        }
                                    }}
                                    as="select">

                                    <option value="">Select</option>
                                    {/***/}
                                    {inventory.map((elem, index) => {

                                        if (elem.inventoryType === "shedIn") {

                                            if (elem.poultryFarm_FK === values.poultryFarm_FK) {

                                                return (<option value={elem?._id}>{elem?.name}</option>)
                                            }
                                        }
                                        else if (elem.inventoryType === "flockIn") {
                                            let date = elem.createdAt;
                                            let pakistaniDate = convertDateToPakistaniFormat(date);

                                            return (allFlock.map((flockObj, index) => {
                                                if (elem.flock_FK == flockObj._id) {
                                                    if (flockObj.poultryFarm_FK === values.poultryFarm_FK) {
                                                        return (<option value={elem._id}>{flockObj.flockType} {pakistaniDate}</option>)
                                                    }
                                                }
                                            })

                                            )

                                        }
                                    })}
                                </Field>
                                <ErrorMessage name="inventory_FK" className='text-danger' component={"div"} />
                            </div>

                            <div className="col-md-10 mt-2">
                                <label for="quantity">Quantity</label>
                                <Field disabled={autoSet} autoComplete='off' id="quantity" className="form-control py-1" type="number" name="quantity" />
                                <ErrorMessage name="quantity" className='text-danger' component={"div"} />
                            </div>

                            <div className="col-md-10 mt-2">
                                <label for="price">Price</label>
                                <Field autoComplete='off' id="price" className="form-control py-1" type="number" name="price" />
                                <ErrorMessage name="price" className='text-danger' component={"div"} />
                            </div>

                        </div>


                        <div className={`row my-4 ${props.open && 'd-none'}`}>
                            <div className='col-md-12 text-center'>
                                <button type='submit' id="SubmitButton">Submit</button>
                            </div>
                        </div>
                    </Form>)
            }}

        </Formik>
    )
}

export default IncomeForm
