import React, { useState } from "react";
import DynamicAntTable from "../assets/DynamicAntTable";
import { Button, ConfigProvider, Dropdown } from "antd";
import { Card } from "react-bootstrap";
import { IoSettingsOutline } from "react-icons/io5";
import {
  EmployeeData,
  PoultryFarmState,
  UpdateHandle,
  allUsers,
  antDCustomTheme,
  defaultApiUrl,
} from "../../Atom";
import { useRecoilState, useRecoilValue } from "recoil";
import { MdOutlineLibraryAdd } from "react-icons/md";
import { GrUpdate } from "react-icons/gr";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { convertDateToPakistaniFormat, userData } from "../assets/GlobleFun";
import { toComma } from "../../GlobalFunctions";
// import toComma from "../../GlobalFunctions";

export function EmployeeTable() {
  let defaultApi = useRecoilValue(defaultApiUrl);
  let employees = useRecoilValue(EmployeeData);
  let allUsersObj = useRecoilValue(allUsers);

  let [employee, setEmployee] = useRecoilState(EmployeeData); // eslint-disable-line no-unused-vars
  const [selectedRow, setSelectedRow] = useState(null);
  const [updateHandle, setUpdateHandle] = useRecoilState(UpdateHandle); // eslint-disable-line no-unused-vars

  let deleteHandler = (elem) => {
    if (window.confirm("Are you sure to want to delete this Employee")) {
      var obj = elem._id;
      axios
        .delete(defaultApi + "/api/employee/delete/" + obj)
        .then((res) => {
          console.log(res);

          // Filter out the deleted item from the Recoil state array
          setEmployee((prevIncomes) =>
            prevIncomes.filter((employee) => employee._id !== obj)
          );

          toast.error("Employee deleted successfully", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong please try again later", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }
  };

  let poultryFarm = useRecoilValue(PoultryFarmState);

  // Custom rendering function for columns
  const renderPoultryName = (record) => {
    let poultryFarmId = record;

    // console.log("poultryFarmId", poultryFarmId)

    poultryFarmId = poultryFarm.map((elem, index) => {
      if (poultryFarmId === elem._id) {
        return elem.name;
      }
    });

    return poultryFarmId; // Return custom value or original ID
  };

  const items =
    JSON.parse(sessionStorage.getItem("UserData")).accountType === "5156"
      ? [
          {
            key: "1",
            label: "Add New",
            onClick: () => handleMenuClick("1", selectedRow),
            icon: <MdOutlineLibraryAdd />,
          },
          {
            key: "2",
            label: "Update",
            onClick: () => handleMenuClick("2", selectedRow),
            icon: <GrUpdate />,
          },
          {
            key: "3",
            danger: true,
            label: "Delete",
            icon: <RiDeleteBin6Line />,
            onClick: () => handleMenuClick("3", selectedRow),
          },
        ]
      : [
          {
            key: "1",
            label: "Add New",
            onClick: () => handleMenuClick("1", selectedRow),
            icon: <MdOutlineLibraryAdd />,
          },
        ];

  let navigate = useNavigate();

  const handleMenuClick = (key, record) => {
    setSelectedRow(record);
    if (key === "1") {
      navigate("/add-Employee");
    } else if (key === "2") {
      // Handle update action
      console.log("Update clicked for:", record);
      setUpdateHandle(record);
      navigate("/add-Employee#update");
    } else if (key === "3") {
      // Handle delete action
      deleteHandler(record);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: '150px',
      
      // ellipsis: false,
      render: (data, record) => {
        // alert(data);
        
        return (
          <strong>{data}</strong>
        );
      },
      // responsive:["xs"],
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: '160px',
      render: (data, record) => {
        // alert(data);

        return (
        <strong>{data}</strong>
        );
      },
      // ellipsis: false,
      // responsive:["xs"],
    },
    {
      title: "Salary",
      dataIndex: "salary",
      key: "salary",
      ellipsis: false,
      render: (data, record) => {
        // alert(data);

        return (
        <strong>{toComma(data)}</strong>
        );
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      ellipsis: false,
      width:"200px"
    },
    {
      title: "CNIC",
      dataIndex: "CNIC",
      key: "CNIC",
      ellipsis: false,
    },
    {
      title: "Poultry Farm",
      dataIndex: "poultryFarm_FK",
      key: "poultryFarm_FK",
      ellipsis: false,
      render: renderPoultryName, // Custom render function to display mapped values
    },
    {
      title: "Added By",
      dataIndex: "user_FK",
      key: "user_FK",
      ellipsis: false,
      width:"300px",
      render: (data, record) => {
        // alert(data);
        return (
          userData(data, allUsersObj) +
          " " +
          convertDateToPakistaniFormat(record.updatedAt)
        );
      },
    },

    {
      title: "Options",
      key: "options",
      render: (_, record) => (
        <Dropdown
          menu={{
            items: items.map((item) => ({
              ...item,
              onClick: () => handleMenuClick(item.key, record),
            })),
          }}
          placement="bottom"
          arrow={{
            pointAtCenter: true,
          }}
        >
          <p className="w-50 text-center">
            <IoSettingsOutline size={"20px"} />
          </p>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className="row">
      <div className="col-md-12">
        <DynamicAntTable column={columns} TData={employees} />
      </div>
    </div>
  );
}
export const EmployeeList = () => {
  let navigate = useNavigate();
  let customTheme = useRecoilValue(antDCustomTheme);
  return (
    <div className="container-fluid">
      <div className="row justify-content-center mt-5">
        <div className="col-md-10">
          <Card className="C-Border shadow px-4">
            <div className="d-flex justify-content-between align-items-center my-3">
              <h4 className="h4 C-color">
                <b>My Employee List</b>
              </h4>
              <ConfigProvider theme={customTheme}>
                <Button
                  type="primary"
                  className="float-end px-5 w-25"
                  onClick={() => {
                    navigate("/add-Employee");
                  }}
                >
                  Add New Employee +
                </Button>
              </ConfigProvider>
            </div>
            <EmployeeTable />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default EmployeeList;
